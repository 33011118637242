import { observer } from 'mobx-react-lite'
import { forwardRef, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import AuthStore from '../../stores/AuthStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import DataTable from './DataTable'
import SalesInfo from './salesInfo'
import SettlementInfo from './settlementInfo'
import ViewInfo from './viewInfo'

const MasterScreen = observer(() => {
  const [providerList, setProviderList] = useState()
  const [toonList, setToonList] = useState()
  const [selectedProviderId, setSelectedProviderId] = useState()
  const [selectedToonId, setSelectedToonId] = useState()
  const days = ['월', '화', '수', '목', '금', '토', '일']
  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ]

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
    },
  }

  const dataTableColumns = [
    { Header: '번호', accessor: 'index' },
    { Header: '작품명', accessor: 'toonTitle' },
    { Header: 'CP사', accessor: 'providerDisplayName' },
    { Header: '정산 수수료율(%)', accessor: 'settlementFeeRate' },
    { Header: '코인 사용수량(개)', accessor: 'totalQuantity' },
    { Header: '총 매출액(원)', accessor: 'settlementAmount' },
    {
      Header: '앱스토어 수수료(원)',
      accessor: 'settlementAppStoreFeeAmount',
    },
    {
      Header: '올웨이즈 수수료(원)',
      accessor: 'settlementAlwayzFeeAmount',
    },
    {
      Header: '순매출액(원, 앱스토어 수수료 제)',
      accessor: 'settlementAmountWithoutAppStoreFee',
    },
    {
      Header: '정산대상금액(원, 전체 수수료 제)',
      accessor: 'settlementConfirmAmount',
    },
  ]

  const handlePeriodSearch = () => {
    AltoonCMSUserStore.loadDashboardInfo(selectedProviderId, selectedToonId)
  }

  const handleProviderIdChange = (event) => {
    setSelectedProviderId(event.target?.value || '')
  }

  const handleToonIdChange = (event) => {
    setSelectedToonId(event.target?.value || '')
  }

  useEffect(() => {
    const tempToken = localStorage.getItem('@altoon@provider@token@')
    const fetchProviderList = async () => {
      const result = await altoonCMSBackendApis.loadProviderList(
        tempToken ?? AuthStore?.token,
      )
      if (result?.status === 200) {
        setProviderList(result?.data)
      }
    }
    const fetchToonList = async () => {
      const result = await altoonCMSBackendApis.getToonData()

      if (result?.status === 200) {
        setToonList(result?.data)
      }
    }
    fetchProviderList()
    if (selectedProviderId) {
      fetchToonList()
    }
  }, [selectedProviderId])

  const CalendarCustomStyling = forwardRef(({ value, onClick }, ref) => (
    <button
      type='button'
      className='px-2 mx-2 font-normal border border-black rounded'
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ))

  return (
    <>
      <div className='grid w-full grid-cols-2 gap-5 my-10 min-w-7xl max-w-7xl select-text'>
        <div className='flexCol text-xl font-bold text-sub-500 text-center col-start-1 col-end-3'>
          <div className='flexRow px-2'>
            <div className='px-2'>
              CP사 선택{' '}
              {providerList?.length > 0 && (
                <select
                  value={selectedProviderId}
                  onChange={handleProviderIdChange}
                >
                  <option value=''>CP사를 선택해주세요</option>
                  {providerList?.map((provider) => (
                    <option key={provider._id} value={provider._id}>
                      {provider.description}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className='flexRow px-2 whitespace-nowrap'>
              작품 선택{' '}
              {toonList?.length > 0 && (
                <select
                  value={selectedToonId}
                  onChange={handleToonIdChange}
                  className='mx-2'
                >
                  <option value=''>전체</option>
                  {toonList
                    ?.filter(
                      (toon) =>
                        toon?.providerInfo?.providerId === selectedProviderId &&
                        toon?.isDisplayed === true &&
                        !toon?.deletedAt,
                    )
                    .map((provider) => (
                      <option key={provider._id} value={provider._id}>
                        {provider.title}
                      </option>
                    ))}
                </select>
              )}
              {!toonList && (
                <div className='px-2 py-2 mx-2 font-normal border border-black rounded whitespace-nowrap text-sm'>
                  CP사를 선택해주세요
                </div>
              )}
            </div>
          </div>
          {/* <div className='flexRow px-2 whitespace-nowrap'>
            월 선택
            <ReactDatePicker
              locale={locale}
              selected={AltoonCMSUserStore?.selectedMonth}
              onChange={(date) => AltoonCMSUserStore?.setSelectedMonth(date)}
              dateFormat='yyyy년 M월'
              showMonthYearPicker
              showTwoColumnMonthYearPicker
              shouldCloseOnSelect
              customInput={<CalendarCustomStyling />}
            />
          </div> */}
          <div className='flexRow py-2 whitespace-nowrap'>
            <div className='flex items-center mr-4'>
              <label className='inline-flex items-center mr-4'>
                <input
                  type='radio'
                  value='month'
                  checked={AltoonCMSUserStore?.dateType === 'month'}
                  onChange={() => AltoonCMSUserStore.setDateType('month')}
                  className='mr-2'
                />
                월별 조회
              </label>
              <label className='inline-flex items-center'>
                <input
                  type='radio'
                  value='period'
                  checked={AltoonCMSUserStore?.dateType === 'period'}
                  onChange={(e) => AltoonCMSUserStore.setDateType('period')}
                  className='mr-2'
                />
                기간 설정
              </label>
            </div>

            {AltoonCMSUserStore?.dateType === 'month' ? (
              <ReactDatePicker
                locale={locale}
                selected={AltoonCMSUserStore?.selectedMonth}
                onChange={(date) => AltoonCMSUserStore?.setSelectedMonth(date)}
                dateFormat='yyyy년 M월'
                showMonthYearPicker
                showTwoColumnMonthYearPicker
                shouldCloseOnSelect
                customInput={<CalendarCustomStyling />}
              />
            ) : (
              <>
                <div className='flex items-center'>
                  <ReactDatePicker
                    locale={locale}
                    selected={AltoonCMSUserStore?.startDate}
                    onChange={(date) => AltoonCMSUserStore?.setStartDate(date)}
                    selectsStart
                    startDate={AltoonCMSUserStore?.startDate}
                    endDate={AltoonCMSUserStore?.endDate}
                    dateFormat='yyyy-MM-dd'
                    customInput={<CalendarCustomStyling />}
                  />
                  <span className='mx-2'>~</span>
                  <ReactDatePicker
                    locale={locale}
                    selected={AltoonCMSUserStore?.endDate}
                    onChange={(date) => AltoonCMSUserStore?.setEndDate(date)}
                    selectsEnd
                    startDate={AltoonCMSUserStore?.startDate}
                    endDate={AltoonCMSUserStore?.endDate}
                    minDate={AltoonCMSUserStore?.startDate}
                    dateFormat='yyyy-MM-dd'
                    customInput={<CalendarCustomStyling />}
                  />
                </div>
                <button
                  type='button'
                  onClick={handlePeriodSearch}
                  className='ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
                >
                  확인
                </button>
              </>
            )}
          </div>
        </div>
        <div className='col-start-1 col-end-3'>
          매일 오전 9시에 <strong>전날 매출</strong>이 업데이트 됩니다.
        </div>
        <div className='col-start-1 col-end-3'>
          <SalesInfo
            selectedProviderId={selectedProviderId}
            selectedToonId={selectedToonId}
          />
        </div>
        <div className='col-start-1 col-end-3'>
          <ViewInfo
            selectedProviderId={selectedProviderId}
            selectedToonId={selectedToonId}
          />
        </div>
        <div className='col-start-1 col-end-3'>
          <SettlementInfo />
        </div>
        <div className='col-start-1 col-end-3'>
          <DataTable
            data={AltoonCMSUserStore?.dashboardInfoByToonId}
            columns={dataTableColumns}
          />
        </div>
        {/* <div className='col-start-1 col-end-3'>
          작품별 지표{' '}
          <SalesInfo
            selectedProviderId={selectedProviderId}
            selectedToonId={selectedToonId}
          />
        </div> */}
      </div>
    </>
  )
})

export default MasterScreen
