/* eslint-disable react/button-has-type */

/* eslint-disable no-alert */
// salesInfo.js
import commaNumber from 'comma-number'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SiGooglesheets } from 'react-icons/si'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import TableData from '../../components/atoms/tableData'
import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import Format from '../../utils/format'

const headers = Format.SettlementExcelHeaders
const headersWithoutDate = Format.SettlementExcelHeaderWithoutDate

const SalesInfo = observer(
  ({ selectedProviderId = '', selectedToonId = '' }) => {
    const className = 'border-2 border-transparent'
    const className2 = 'border-2 border-transparent font-bold'

    const [salesData, setSalesData] = useState(null)
    const [totalCoinUsed, setTotalCoinUsed] = useState(0)
    const [totalSales, setTotalSales] = useState(0)
    const [netSales, setNetSales] = useState(0)

    /// 매출 현황 데이터 가져오기
    useEffect(() => {
      const fetchData = async () => {
        const result = await AltoonCMSUserStore.loadDashboardInfo(
          selectedProviderId,
          selectedToonId,
        )
        if (result === true) {
          const tempSalesData = Object.entries(
            AltoonCMSUserStore.dashboardInfoByDate,
          ).map(([date, sales]) => {
            return { date, sales }
          })
          tempSalesData.sort((a, b) => new Date(a.date) - new Date(b.date))
          setSalesData(tempSalesData)

          // 총 매출액 계산
          const totalSales = tempSalesData.reduce(
            (sum, item) => sum + item.sales,
            0,
          )
          setTotalSales(totalSales)

          // 순 매출액 계산
          const netSales = Object.values(
            AltoonCMSUserStore.dashboardInfoByToonId,
          ).reduce(
            (sum, item) =>
              sum +
              (item.settlementConfirmAmount !== '-'
                ? item.settlementConfirmAmount || 0
                : 0),
            0,
          )
          setNetSales(netSales)
        }
      }
      fetchData()
    }, [
      AltoonCMSUserStore.selectedMonth,
      AltoonCMSUserStore.dateType,
      AltoonCMSUserStore.startDate,
      AltoonCMSUserStore.endDate,
      selectedProviderId,
      selectedToonId,
    ])

    return (
      <div className='flex flex-col flex-1 h-full select-none'>
        <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
          {AltoonCMSUserStore.loadingDashboardInfo ? (
            <div className='text-lg font-bold'>
              매출 현황을 불러오는 중입니다.
            </div>
          ) : (
            <>
              <div className='flex justify-between items-center mb-4'>
                <h1 className='text-xl font-bold'>매출 현황</h1>
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex'
                  onClick={async () => {
                    const confirmResult = window.confirm(
                      AltoonCMSUserStore.dateType === 'period'
                        ? `정말 해당 기간(${moment(
                            AltoonCMSUserStore?.startDate,
                          ).format('YY.MM.DD')} ~ ${moment(
                            AltoonCMSUserStore?.endDate,
                          ).format(
                            'YY.MM.DD',
                          )})의 매출 현황을 다운로드 하시겠습니까? 오랜 시간이 걸릴 수 있습니다.`
                        : `정말 ${AltoonCMSUserStore?.selectedMonth.getFullYear()}년 ${
                            AltoonCMSUserStore?.selectedMonth.getMonth() + 1
                          }월 매출 현황을 다운로드 하시겠습니까? 오랜 시간이 걸릴 수 있습니다.`,
                    )
                    if (confirmResult === true) {
                      const loadedTotalOrders =
                        AltoonCMSUserStore?.dashboardTotalInfo
                      const loadedOrders =
                        AltoonCMSUserStore?.dashboardInfoByToonId

                      const workbook = new ExcelJS.Workbook()
                      const totalSheet = workbook.addWorksheet('전체 매출 내역')
                      const worksheetData = loadedOrders.map((order) => {
                        const baseData =
                          Format.formatOrderForSettlementExcel(order)
                        return {
                          period: `${moment(
                            AltoonCMSUserStore.startDate,
                          ).format('YY.MM.DD')} ~ ${moment(
                            AltoonCMSUserStore.endDate,
                          ).format('YY.MM.DD')}`, // 상수 값 추가
                          ...baseData,
                        }
                      })
                      totalSheet.columns =
                        AltoonCMSUserStore?.dateType === 'period'
                          ? [
                              {
                                header: '조회기간',
                                key: 'period', // 위에서 추가한 속성 이름
                                width: 20,
                              },
                              ...headersWithoutDate,
                            ]
                          : headers
                      totalSheet.addRows(worksheetData)

                      // 헤더 스타일 적용
                      const headerRow = totalSheet.getRow(1)
                      headers.forEach((header, index) => {
                        headerRow.getCell(index + 1).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'FFEA3F49' },
                        }
                      })
                      headerRow.font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true,
                      }

                      // 두 번째 시트: 일별 breakdown
                      const dailySheet = workbook.addWorksheet('일별 매출 내역')
                      dailySheet.columns = [
                        { header: '날짜', key: 'date', width: 15 },
                        ...headersWithoutDate,
                      ]

                      // 날짜별로 데이터 그룹화
                      const dailyData = loadedTotalOrders.reduce(
                        (acc, order) => {
                          const date = moment(order.createdDate).format(
                            'YYYY-MM-DD',
                          )
                          if (!acc[date]) {
                            acc[date] = []
                          }
                          acc[date].push(order)
                          return acc
                        },
                        {},
                      )

                      // 일별 데이터 추가
                      Object.entries(dailyData)
                        .sort(
                          ([dateA], [dateB]) =>
                            new Date(dateA) - new Date(dateB),
                        ) // 날짜 내림차순 정렬
                        .forEach(([date, orders]) => {
                          const dailyOrders = orders.map((order) => {
                            const settlementAmountWithoutAppStoreFee =
                              order.settlementFeeRate !== 1 ||
                              AltoonCMSUserStore.isMasterAccount === true
                                ? order.settlementAmount -
                                    order.settlementAppStoreFeeAmount || 0
                                : '-'

                            return {
                              date,
                              ...Format.formatOrderForSettlementExcel(order),
                              settlementFeeRate:
                                (1 - order.settlementFeeRate) * 100 || 0, // 수수료율 계산 추가
                              settlementAmountWithoutAppStoreFee,
                            }
                          })
                          dailySheet.addRows(dailyOrders)
                        })

                      // 일별 시트 헤더 스타일 적용
                      const dailyHeaderRow = dailySheet.getRow(1)
                      dailySheet.columns.forEach((_, index) => {
                        dailyHeaderRow.getCell(index + 1).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'FFEA3F49' },
                        }
                      })
                      dailyHeaderRow.font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true,
                      }

                      const mimeType = {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      }
                      const buffer = await workbook.xlsx.writeBuffer()
                      const blob = new Blob([buffer], mimeType)
                      saveAs(
                        blob,
                        AltoonCMSUserStore?.dateType === 'period'
                          ? `${
                              AltoonCMSUserStore?.providerInfo
                                ?.providerDisplayName
                            }_매출내역_${moment(
                              AltoonCMSUserStore?.startDate,
                            ).format('YY.MM.DD')}~${moment(
                              AltoonCMSUserStore?.endDate,
                            ).format('YY.MM.DD')}.xlsx`
                          : `${
                              AltoonCMSUserStore?.providerInfo
                                ?.providerDisplayName
                            }_매출내역_${moment().format('YYYY.MM')}.xlsx`,
                      )
                    }
                  }}
                >
                  <SiGooglesheets className='mr-2 w-6 h-auto' /> 매출 내역
                  다운로드
                </button>
              </div>
              <table className='border-transparent'>
                <tbody>
                  <tr>
                    <TableData className={className}>총 매출액</TableData>
                    <TableData className={className2}>
                      {commaNumber(totalSales || 0)}원
                    </TableData>
                  </tr>
                  <tr>
                    <TableData className={className}>순 매출액</TableData>
                    <TableData className={className2}>
                      {commaNumber(Math.ceil(netSales) || 0)}원
                    </TableData>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div className='flex flex-col flex-1 px-10 py-10 bg-white shadow-md rounded-xl'>
            <h2 className='text-lg font-bold'>일별 매출 현황</h2>
            <BarChart width={1000} height={300} data={salesData}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis width={80} />
              <Tooltip formatter={(value) => value.toLocaleString()} />
              <Legend />
              <Bar dataKey='sales' fill='#82ca9d' />
            </BarChart>
          </div>
        </div>
      </div>
    )
  },
)

export default SalesInfo
