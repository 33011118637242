import { AlwayzImage } from '@wetripod/alwayz-frontend-shared'
import { observer } from 'mobx-react-lite'
import React, { forwardRef, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ClipLoader } from 'react-spinners'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import backendApis from '../../utils/backendApis.ts'
import resize from '../../utils/resize'

const days = ['월', '화', '수', '목', '금', '토', '일']
const months = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
]
const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
}
const CalendarCustomStyling = forwardRef(({ value, onClick }, ref) => (
  <button
    type='button'
    className='px-2 mx-2 font-normal border border-black rounded'
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
))

const ToonPromotionAdminScreen = observer(() => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [ctrData, setCtrData] = useState()
  const [sortField, setSortField] = useState('component') // 정렬 필드 상태 추가
  const [sortDirection, setSortDirection] = useState('asc') // 정렬 방향 상태 추가
  const [showGrouped, setShowGrouped] = useState(false) // 그룹화 표시 여부

  const [mainNewToons, setMainNewToons] = useState([])
  const [previewToons, setPreviewToons] = useState([])
  const [commentRecommendToons, setCommentRecommendToons] = useState([])
  const [trendingToons, setTrendingToons] = useState([])

  // 컴포넌트 이름 변환
  const getComponentName = (component) => {
    const nameMap = {
      banner_main: 'a_메인 배너',
      main_quicklink: 'b_퀵링크',
      main_recentlyViewed: 'c_최근 본 작품',
      main_recentlyViewed_seeAll: 'd_최근 본 작품 더보기',
      main_new: 'e_새로운 작품',
      banner_short: 'f_띠배너',
      main_trending: 'g_오늘 인기 TOP 10',
      main_commentRecommend: 'h_독자 픽!',
      main_preview: 'i_미리보기 한 컷!',
      BottomBar: 'j_하단바',
      main_modal: 'k_모달',
      main_urlRedirect_alfarmFertMission: 'l_올팜 비료미션',
    }
    return nameMap[component] || component
  }

  const handlePeriodSearch = () => {
    AltoonCMSUserStore.loadMainpageUserflow(startDate, endDate)
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const sortedData = ctrData?.sort((a, b) => {
    const multiplier = sortDirection === 'asc' ? 1 : -1
    if (sortField === 'component') {
      return multiplier * a.component.localeCompare(b.component)
    }
    if (sortField === 'clicks') {
      return multiplier * (a.clicks - b.clicks)
    }
    return multiplier * (a.index - b.index)
  })

  const getGroupedData = () => {
    if (!showGrouped) return sortedData

    // 구좌명 기준으로 데이터 그룹화
    const groupedMap = sortedData?.reduce((acc, row) => {
      if (!acc[row.component]) {
        acc[row.component] = {
          component: row.component,
          clicks: 0,
          totalUsers: row.totalUsers,
        }
      }
      acc[row.component].clicks += row.clicks
      return acc
    }, {})

    // 그룹화된 데이터를 배열로 변환하고 CTR 계산
    return Object.values(groupedMap).map((group) => ({
      ...group,
      index: '-',
      ctr: (group.clicks / group.totalUsers) * 100,
    }))
  }

  useEffect(() => {
    const loadAndProcessData = async () => {
      try {
        const data = AltoonCMSUserStore.userflowData
        const totalUsers = new Set(data.map((flow) => flow.userId)).size

        // 컴포넌트별, 인덱스별 클릭수 집계
        const clickMap = new Map()
        data.forEach((flow) => {
          const { component, index } = flow.params
          if (!clickMap.has(component)) {
            clickMap.set(component, new Map())
          }
          const indexMap = clickMap.get(component)
          indexMap.set(index, (indexMap.get(index) || 0) + 1)
        })

        // CTR 데이터 생성
        const ctrResults = []
        clickMap.forEach((indexMap, component) => {
          indexMap.forEach((clicks, index) => {
            ctrResults.push({
              component: getComponentName(component),
              index: index || 0,
              clicks,
              totalUsers,
              ctr: (clicks / totalUsers) * 100,
            })
          })
        })

        setCtrData(ctrResults)
      } catch (error) {
        console.error('Error loading userflow data:', error)
      }
    }
    loadAndProcessData()
  }, [AltoonCMSUserStore.userflowData])

  useEffect(() => {
    loadPromotionToons()
    loadTrendingToons()
  }, [])

  const loadPromotionToons = async () => {
    const promotionData = await altoonCMSBackendApis.loadRecommendingToons({
      selectedGenres: 'all',
    })
    if (promotionData?.status === 200) {
      const mainNewToons = promotionData?.data?.filter(
        (toon) => toon.recommendInfo?.recommendType?.mainNew,
      )
      const previewToons = promotionData?.data.filter(
        (toon) => toon.recommendInfo?.recommendType?.preview,
      )
      const commentRecommendToons = promotionData?.data?.filter(
        (toon) => toon.recommendInfo?.recommendType?.commentRecommend,
      )
      setMainNewToons(mainNewToons)
      setPreviewToons(previewToons)
      setCommentRecommendToons(commentRecommendToons)
    }
  }

  const loadTrendingToons = async () => {
    const trendingToons = await backendApis.loadTrendingToons()
    if (trendingToons?.status === 200) {
      setTrendingToons(trendingToons?.data)
    }
  }

  // 모바일 디바이스 미리보기
  const MobilePreview = ({ ctrData }) => {
    const getPositionCTR = (component, index) => {
      const position = ctrData?.find(
        (data) =>
          data?.component === getComponentName(component) &&
          data?.index === index,
      )
      return position ? position?.ctr?.toFixed(2) : '0.00'
    }

    // main_banner의 최대 인덱스 찾기
    const getMaxBannerIndex = () => {
      const bannerData = ctrData?.filter(
        (data) => data?.component === 'banner_main',
      )
      if (bannerData?.length === 0 || !bannerData) return 0
      return Math.max(...bannerData?.map((data) => data.index))
    }

    const maxBannerIndex = getMaxBannerIndex()

    return (
      <div className='w-[375px] h-[800px] bg-white relative border border-gray-200 rounded-md shadow-lg'>
        <div className='w-full h-full overflow-y-scroll'>
          {/* Status Bar */}
          <div className='h-[48px] bg-white sticky top-0 z-10 flex items-center justify-center rounded-t-md'>
            <div className='w-[120px] h-[24px] bg-black z-10 rounded-full absolute top-[12px]' />
          </div>

          {/* 메인 배너 */}
          <div className='relative'>
            <div className='w-full h-[220px] bg-gray-200' />
            <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-3'>
              <div className='text-white text-xs grid grid-cols-4 gap-x-4 gap-y-1 max-h-[180px] overflow-y-auto'>
                {Array.from({ length: maxBannerIndex + 1 }, (_, index) => (
                  <div
                    key={index}
                    className='flex items-center justify-between whitespace-nowrap'
                  >
                    <span>배너 {(index + 1).toString().padStart(2, '0')}</span>
                    <span className='ml-2'>
                      {getPositionCTR('banner_main', index)}%
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* 퀵링크 */}
          <div className='w-full flex justify-between px-[15px] my-[30px]'>
            {[0, 1, 2, 3, 4].map((index) => (
              <div key={index} className='relative'>
                <div className='w-[60px] h-[60px] bg-gray-400 rounded-lg' />
                <div className='absolute -bottom-4 left-0 right-0 text-center text-[10px] text-red-500'>
                  {getPositionCTR('main_quicklink', index)}%
                </div>
              </div>
            ))}
          </div>

          {/* 최근 본 작품 */}
          <div className='py-[15px] mx-[15px]'>
            <div className='flex items-center justify-between mb-[8px]'>
              <div className='text-[16px] font-bold'>최근 본 작품</div>
            </div>
            <div className='flex overflow-x-auto hide-scrollbar'>
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <div key={index} className='mr-[8px] last:mr-0'>
                  <div className='relative'>
                    <div className='w-[100px] h-[120px] bg-gray-200 rounded-lg border border-[#F5F5F5]' />
                    <div className='absolute top-[4px] right-[4px] font-bold text-[14px] text-red-500'>
                      {getPositionCTR('main_recentlyViewed', index)}%
                    </div>
                  </div>
                  <div className='py-1 flex flex-col items-start h-[60px]'>
                    <div className='w-full h-[13px] bg-gray-200 rounded mb-1' />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 새로운 작품 */}
          <div className='py-[15px] mx-[15px]'>
            <div className='flex items-center justify-between mb-[8px]'>
              <div className='text-[16px] font-bold'>새로운 작품 등장!</div>
            </div>
            <div className='grid grid-cols-3 gap-[8px]'>
              {mainNewToons?.slice(0, 6).map((toon, index) => (
                <div key={index} className='relative'>
                  <div className='relative'>
                    {/* <div className='w-[113px] h-[150px] bg-gray-200 rounded-lg border border-[#F5F5F5]' /> */}
                    <div className='absolute top-[4px] left-[4px] w-[30px] h-[13px] bg-gray-300' />
                    <AlwayzImage
                      lazy
                      src={resize(toon?.thumbnail, 254, 340, 'fill')}
                      alt={toon?.title}
                      className='w-[113px] h-[150px] rounded-lg border border-[#F5F5F5]'
                    />
                    <div className='absolute top-[4px] right-[2px] font-bold text-[14px] text-red-500 bg-white px-[4px] py-[2px] rounded-md'>
                      {getPositionCTR('main_new', index)}%
                    </div>
                  </div>
                  <div className='py-1 flex flex-col items-start h-[60px]'>
                    <h3 className='w-full h-[13px] rounded mb-1 text-[14px]'>
                      {toon?.title?.length > 24
                        ? `${toon?.title.slice(0, 24)}..`
                        : `${toon?.title}`}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 숏배너 */}
          <div className='w-full h-[100px] bg-gray-200' />

          {/* 오늘 인기 TOP 10 */}
          <div className='pl-[15px] py-[15px]'>
            <div className='flex items-center justify-between mb-[8px] pr-[15px]'>
              <div className='text-[16px] font-bold'>오늘 인기 TOP 10</div>
            </div>
            <div className='flex overflow-x-auto hide-scrollbar'>
              {trendingToons?.slice(0, 10)?.map((toon, index) => (
                <div key={index} className='px-[22px] relative'>
                  <div className='relative'>
                    <div className='w-[113px] h-[150px] bg-gray-200 rounded-lg border border-[#F5F5F5] relative' />
                    <div className='absolute top-[4px] right-[4px] font-bold text-[14px] text-red-500'>
                      {getPositionCTR('main_trending', index)}%
                    </div>
                  </div>
                  <div
                    className={`${
                      index === 9
                        ? 'text-[67px] -left-[22px]'
                        : 'text-[75px] left-0'
                    } justify-start items-end flex font-bold text-gray-600 opacity-60 absolute bottom-[25px] w-[79px]`}
                  >
                    {index + 1}
                  </div>
                  <div className='py-1'>
                    {/* 자동으로 텍스트가 한줄에 끊기게 하기 */}
                    <h3 className='h-[20px] rounded mb-1 w-[80px] mx-auto text-[14px] truncate'>
                      {toon?.toonTitle}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 독자 픽! */}
          <div className='pl-[15px] py-[15px]'>
            <div className='flex items-center justify-between mb-[8px] pr-[15px]'>
              <div className='text-[16px] font-bold'>독자 픽!</div>
            </div>
            <div className='flex overflow-x-auto hide-scrollbar'>
              {commentRecommendToons?.slice(0, 3)?.map((toon, index) => (
                <div key={index} className='pr-[15px] flex'>
                  <div className='relative'>
                    <div className='' />
                    <AlwayzImage
                      lazy
                      src={resize(toon?.thumbnail, 254, 340, 'fill')}
                      alt={toon?.title}
                      className='w-[113px] h-[150px] rounded-lg border border-[#F5F5F5]'
                    />
                    <div className='absolute top-[4px] right-[4px] font-bold text-[14px] text-red-500 bg-white px-[4px] py-[2px] rounded-md'>
                      {getPositionCTR('main_commentRecommend', index)}%
                    </div>
                  </div>
                  <div className='w-[188px] h-[150px] flex flex-col justify-between px-[8px] text-start'>
                    <div className='py-[8px]'>
                      <h3 className='h-[14px] w-[120px] rounded mb-[8px] text-[14px]'>
                        {toon?.title?.length > 12
                          ? `${toon?.title.slice(0, 12)}..`
                          : `${toon?.title}`}
                      </h3>
                      <div className='flex gap-1'>
                        <div className='h-[10px] w-[40px] bg-gray-200 rounded' />
                        <div className='h-[10px] w-[40px] bg-gray-200 rounded' />
                      </div>
                    </div>
                    <div className='w-full h-[75px] p-[8px] rounded bg-[#EBEBEB] mb-[8px]'>
                      <div className='flex items-center gap-[2px] mb-[8px]'>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <div
                            key={star}
                            className='w-[8px] h-[8px] bg-gray-300 rounded-full'
                          />
                        ))}
                      </div>
                      <div className='h-[40px] bg-gray-300 rounded' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 미리보기 한 컷! */}
          <div className='pl-[15px] py-[15px]'>
            <div className='flex items-center justify-between mb-[8px] pr-[15px]'>
              <div className='text-[16px] font-bold'>미리보기 한 컷!</div>
            </div>
            <div className='flex overflow-x-auto hide-scrollbar'>
              {previewToons?.slice(0, 3)?.map((toon, index) => (
                <div key={index} className='pr-[15px]'>
                  <div className='relative'>
                    <AlwayzImage
                      lazy
                      src={resize(
                        toon?.previewInfo?.imageUri,
                        1000,
                        1250,
                        'fill',
                      )}
                      alt={toon?.title}
                      className='w-[300px] h-[375px] rounded-lg border border-[#F5F5F5]'
                    />
                    <div className='absolute top-[4px] right-[4px] font-bold text-[14px] text-red-500 bg-white px-[4px] py-[2px] rounded-md'>
                      {getPositionCTR('main_preview', index)}%
                    </div>
                  </div>
                  <div className='text-start py-1'>
                    <h3 className='h-[14px] w-[200px] rounded mb-3 text-[14px]'>
                      {toon?.title?.length > 24
                        ? `${toon?.title.slice(0, 24)}...`
                        : `${toon?.title}`}
                    </h3>
                    <h3 className='h-[11px] w-[250px] rounded mb-1 text-[10px]'>
                      {toon?.previewInfo?.introduction?.length > 50
                        ? `${toon?.previewInfo?.introduction?.slice(0, 50)}...`
                        : `${toon?.previewInfo?.introduction}`}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='h-[50px]' />
        </div>
      </div>
    )
  }
  // CTR 데이터 테이블 (이전과 동일)
  const CTRTable = () => {
    return (
      <div className='w-[800px] p-6'>
        <div className='flex items-center'>
          <ReactDatePicker
            locale={locale}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat='yyyy-MM-dd'
            customInput={<CalendarCustomStyling />}
          />
          <span className='mx-2'>~</span>
          <ReactDatePicker
            locale={locale}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat='yyyy-MM-dd'
            customInput={<CalendarCustomStyling />}
          />
          <button
            type='button'
            onClick={handlePeriodSearch}
            className='ml-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
          >
            확인
          </button>
          <label className='ml-4 flex items-center'>
            <input
              type='checkbox'
              checked={showGrouped}
              onChange={(e) => setShowGrouped(e.target.checked)}
              className='mr-2'
            />
            구좌별 합산 보기
          </label>
          <div className='ml-auto text-right'>
            <p className='text-sm text-gray-600'>
              총 노출수: {ctrData?.[0]?.totalUsers?.toLocaleString() ?? 0}{' '}
              (전체배포 시 x10)
            </p>
          </div>
        </div>

        <table className='w-full border-collapse'>
          <thead>
            <tr className='bg-gray-100'>
              <th
                className='p-2 border text-left cursor-pointer hover:bg-gray-200'
                onClick={() => handleSort('component')}
              >
                구좌명{' '}
                {sortField === 'component' &&
                  (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className='p-2 border text-left'>순서</th>
              <th
                className='p-2 border text-right cursor-pointer hover:bg-gray-200'
                onClick={() => handleSort('clicks')}
              >
                클릭수{' '}
                {sortField === 'clicks' &&
                  (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className='p-2 border text-right'>CTR(%)</th>
            </tr>
          </thead>
          <tbody>
            {getGroupedData()?.map((row, i) => (
              <tr key={i} className='hover:bg-gray-50'>
                <td className='p-2 border'>{row.component}</td>
                <td className='p-2 border'>{row.index}</td>
                <td className='p-2 border text-right'>
                  {row.clicks.toLocaleString()}
                </td>
                <td className='p-2 border text-right'>{row.ctr.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold mb-8'>
        메인페이지 구좌별 CTR (10% 배포 중)
      </h1>
      <div className='flex'>
        <MobilePreview ctrData={ctrData} />
        <CTRTable />
      </div>
      {AltoonCMSUserStore.isLoading && <LoadingIndicator />}
    </div>
  )
})

export default ToonPromotionAdminScreen
