import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { BottomSheet } from 'react-spring-bottom-sheet'

import AltoonAction from '../actions/AltoonAction'
import AlwayzImage from '../components/atoms/AlwayzImage'
import ToonModal from '../components/templates/ToonModal'
import AltoonTagComponent from '../components/toon/AltoonTagComponent'
import NovelChapterComponent from '../components/toon/NovelChapterComponent'
import THImageText from '../components/treasureHunt/THImageText'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'
import resize from '../utils/resize'

const AltoonNovelDetailPage = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const scrollContainerRef = useRef(null)
  const searchParams = new URLSearchParams(location.search)
  const novelId = searchParams.get('novelId')

  const token = AuthStore.token

  const [novelData, setNovelData] = useState()
  const [chapterInfo, setChapterInfo] = useState()
  const [viewLogData, setViewLogData] = useState()
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openBottomSheet, setOpenBottomSheet] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dayNameGenerator = (date) => {
    switch (date) {
      case 'Mon':
        return '월'
      case 'Tue':
        return '화'
      case 'Wed':
        return '수'
      case 'Thu':
        return '목'
      case 'Fri':
        return '금'
      case 'Sat':
        return '토'
      case 'Sun':
        return '일'
      default:
        return '월'
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (!novelId || novelId === 'undefined') {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
        return
      }

      const novelData = await backendApis.loadNovelInfoById({ novelId })
      if (novelData?.status === 200) {
        setNovelData(novelData.data)
      }

      const chapterData = await backendApis.loadNovelDetail({ novelId })
      if (chapterData?.status === 200) {
        setChapterInfo(chapterData.data)
      }

      const viewLog = await backendApis.loadNovelViewLog({ novelId })

      if (viewLog?.status === 200) {
        setViewLogData(viewLog.data)
      }

      await backendApis.logToonAction(
        'AltoonNovelDetailPage',
        'enteredScreen',
        {
          novelId,
        },
      )

      setIsLoading(false)
    }

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    fetchData()
  }, [novelId])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 bg-[#ffffffe0] w-full z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            navigate(`/stack/altoon-main?token=${token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <button
          type='button'
          className='px-[4vw] text-[3.6vw]'
          onClick={() => {
            backendApis.logToonAction('AltoonNovelDetailPage', 'linkShare', '')

            AltoonAction.linkShare({
              shareType: 'Altoon_novelShare',
              title: `[올툰]`,
              description: `올툰에서 웹소설 [${
                novelData?.title?.length > 20
                  ? `${novelData?.title?.slice(0, 20)}...`
                  : novelData?.title
              }] 작품을 추천해요!`,
              imageUrl: novelData?.thumbnail,
              payload: {
                novelId,
              },
            })
          }}
        >
          <img
            src='/images/toon/shareIcon.png'
            alt='share'
            className='w-[5vw] h-[5vw]'
          />
        </button>
      </section>
    )
  }

  const NovelInfoSection = () => {
    return (
      <div className='flex flex-col items-center'>
        <div className='flex flex-row items-center justify-center rounded-lg'>
          <AlwayzImage
            src={resize(novelData?.thumbnail, 234, 328, 'fill')}
            alt={novelData?.title}
            className='w-[40vw] h-[52vw] rounded-lg flex-shrink-0'
            lazy
          />
          <div className='w-full h-[52vw] flex flex-col pl-2'>
            {novelData?.bannerImage && (
              <img
                src={novelData?.bannerImage}
                alt={novelData?.title}
                className='w-full h-[16vh] rounded-t-lg'
              />
            )}
            <div className='p-2 text-xs'>
              <div className='text-lg font-semibold'>{novelData?.title}</div>
              <div className='flex flex-row pb-[4vw]'>
                <div>{novelData?.author}</div>
                {novelData?.weekday && (
                  <div className='text-[#999999] flex flex-row'>
                    <div className='mx-2'>|</div>
                    {novelData?.weekday?.map((day, index) => {
                      const dayString = dayNameGenerator(day)
                      return index === novelData.weekday.length - 1
                        ? `${dayString} 연재`
                        : `${dayString}, `
                    })}
                  </div>
                )}
              </div>

              <div className='pb-[4vw] text-[2.8vw]'>
                <button
                  type='button'
                  className='flexRow items-start text-gray-500 text-start max-h-[8vw] overflow-clip'
                  onClick={() => {
                    setModalProp({ toonInfo: novelData })
                    setModalType('toonDescription')
                    setOpenModal(true)
                  }}
                >
                  <THImageText
                    text={
                      novelData?.description?.length > 20
                        ? `${novelData?.description
                            ?.slice(0, 20)
                            ?.replace(/\\n/g, '\n')}..`
                        : novelData?.description?.replace(/\\n/g, '\n')
                    }
                  />

                  {novelData?.description?.length > 20 && (
                    <img
                      src='/images/toon/ic_detail_arrow.png'
                      alt='arrowDown'
                      className='w-[3.6vw] h-[3.6vw] ml-1'
                    />
                  )}
                </button>
              </div>
              <div className='pb-[2vw] flex flex-row text-[2.4vw] overflow-scroll flex-wrap'>
                {novelData?.tagList?.hot === true && (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag='hot'
                    type='detailPage'
                  />
                )}
                {novelData?.tagList?.new && (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag='new'
                    type='detailPage'
                  />
                )}
                {novelData?.tagList?.mainGenre?.map((tag) => (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag={tag}
                    type='detailPage'
                  />
                ))}
                {novelData?.tagList?.subGenre?.slice(0, 5).map((tag) => (
                  <AltoonTagComponent
                    key={Math.random()}
                    tag={tag}
                    type='detailPage'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const GoodsCountComponent = () => {
    const isOnWaitForFreePromotion =
      moment().isAfter(novelData?.promotionInfo?.waitForFree?.eventStartedAt) &&
      moment().isBefore(novelData?.promotionInfo?.waitForFree?.eventEndedAt)

    if (isOnWaitForFreePromotion) {
      return (
        <>
          <div className='w-full py-[2vw] flexRow justify-between items-center'>
            <div>
              전체 {chapterInfo?.length}화{' '}
              <span className='text-[#FF3E3E] ml-[1vw] text-[3.4vw] font-normal'>
                무료{' '}
                {
                  chapterInfo.filter((chapter) => chapter.isFree === true)
                    .length
                }
                화
              </span>
            </div>
          </div>
          <div className='w-full py-[2vw] flexRow justify-between items-center'>
            <button
              type='button'
              className='w-[45vw] px-2 py-[3vw] flexRow items-center justify-between rounded bg-[#F6F6FA] text-[3.2vw] font-semibold'
              onClick={() => {
                setOpenBottomSheet(true)
              }}
            >
              <div>기다리면 무료</div>
              <div>{viewLogData?.waitForFreeAvailable ? 1 : 0}회</div>
            </button>

            <button
              type='button'
              className='w-[45vw] px-[4vw] py-[3vw] flexRow items-center justify-between rounded bg-[#F6F6FA] text-[3.2vw] font-semibold'
              onClick={() => {
                // navigate(`/altoon-ticket?token=${token}`)
              }}
            >
              <div>내 코인 </div>
              <div className='flexRow'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-[4vw] h-[4vw] mx-1'
                />
                {commaNumber(
                  AltoonUserStore?.userData?.toonCoin > 0
                    ? AltoonUserStore?.userData?.toonCoin || 0
                    : 0,
                )}
                C
              </div>
            </button>
          </div>
        </>
      )
    }

    return (
      <div className='w-full py-[2vw] flexRow justify-between items-center'>
        <div>
          전체 {chapterInfo?.length}화{' '}
          <span className='text-[#FF3E3E] ml-[1vw] text-[3.4vw] font-normal'>
            무료{' '}
            {chapterInfo.filter((chapter) => chapter.isFree === true).length}화
          </span>
        </div>

        <button
          type='button'
          className='w-[45vw] px-[4vw] py-[3vw] flexRow items-center justify-between rounded bg-[#F6F6FA] text-[3.2vw] font-semibold'
          onClick={() => {
            // navigate(`/altoon-ticket?token=${token}`)
          }}
        >
          <div>내 코인 </div>
          <div className='flexRow'>
            <img
              src='/images/toon/toonCoin.png'
              alt='toonCoin'
              className='w-[4vw] h-[4vw] mx-1'
            />
            {commaNumber(
              AltoonUserStore?.userData?.toonCoin > 0
                ? AltoonUserStore?.userData?.toonCoin || 0
                : 0,
            )}
            C
          </div>
        </button>
      </div>
    )
  }

  const ChapterList = () => {
    return (
      <div className='pt-[4vw] pb-[10vw]'>
        {chapterInfo?.map((chapter) => {
          return (
            <NovelChapterComponent
              key={chapter._id}
              type='main'
              novelData={novelData}
              viewLogData={viewLogData}
              chapterInfo={chapter}
              chapterCount={chapterInfo.length}
              setModalType={setModalType}
              setModalProp={setModalProp}
              setOpenModal={setOpenModal}
            />
          )
        })}
      </div>
    )
  }

  const ScrollButton = () => {
    return (
      <div type='button' className='w-[8vw] fixed bottom-[2vh] right-4'>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = 0
            }
          }}
        >
          <img src='/images/toon/toTopIcon.png' alt='up' className='w-full' />
        </button>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight
            }
          }}
        >
          <img
            src='/images/toon/toBottomIcon.png'
            alt='up'
            className='w-full'
          />
        </button>
      </div>
    )
  }

  const WaitForFreeIntroComponent = ({ number, mainText, subText }) => {
    return (
      <div>
        <div className='flexRow justify-start w-full mb-[4vw] text-[3.6vw] whitespace-nowrap'>
          <img
            src={`/images/toon/waitForFreeDescription${number}.png`}
            alt='waitForFreeIcon'
            className='w-[14vw] h-[14vw] mr-[4vw]'
          />
          <div className='flexCol items-start'>
            <div className='text-[3.8vw] text-[#71737C] font-semibold'>
              {subText}
            </div>
            <div className='text-[4.5vw] text-[#464953] font-bold'>
              {mainText}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className='w-full overflow-y-scroll pt-[8vh]'
      style={{ height: '100vh' }}
      ref={scrollContainerRef}
    >
      <HeaderComponent />
      {chapterInfo?.length > 0 && (
        <div className='px-4'>
          <NovelInfoSection />
          <GoodsCountComponent />
          <ChapterList />
        </div>
      )}
      <ScrollButton />
      {isLoading && <LoadingIndicator />}

      {openModal && (
        <ToonModal
          modalName={modalType}
          modalProp={modalProp}
          setOpenTicketModal={setOpenModal}
        />
      )}
      <BottomSheet
        className='w-full border-none outline-none bottom-sheet relative z-20'
        onDismiss={() => {
          setOpenBottomSheet(false)
        }}
        scrollLocking={false}
        header={false}
        open={openBottomSheet}
      >
        <div className='p-[6vw]'>
          <div
            className='pb-4 text-[6vw] font-bold'
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'keep-all',
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {novelData?.promotionInfo?.waitForFree?.waitingTime || 3}
            시간마다 1장 무료
          </div>

          <WaitForFreeIntroComponent
            number={1}
            mainText={`${
              novelData?.promotionInfo?.waitForFree?.waitingTime || 3
            }시간 기다리면 다시 이용권 1장 받아요`}
            subText={`'기다리면 무료' 이용권을 사용한 후`}
          />
          <WaitForFreeIntroComponent
            number={2}
            mainText='기다무 이용권으로 감상할 수 없어요'
            subText={`최신 ${
              novelData?.promotionInfo?.waitForFree?.recentChapterLimit || 10
            }회차는`}
          />
          <WaitForFreeIntroComponent
            number={3}
            mainText='작품을 3일동안 감상할 수 있어요'
            subText={`'기다리면 무료' 이용권을 사용하면`}
          />
          <div className='w-[60vw] px-[6vw] py-[4vw] ml-[14vw] my-[4vw] flexCol bg-[#F6F6FA] text-[4vw] rounded-lg'>
            <div className='w-full flexRow items-center justify-between'>
              <div className='font-semibold'>나의 기다리면 무료</div>
              <div className='font-bold'>
                {viewLogData?.waitForFreeAvailable ? 1 : 0}회
              </div>
            </div>
            {!viewLogData?.waitForFreeAvailable && (
              <div className='mt-[2vw] text-[3vw] text-[#71737C] font-bold'>
                {moment(viewLogData?.lastWaitForFreeUsedAt)
                  .add(
                    novelData?.promotionInfo?.waitForFree?.waitingTime || 3,
                    'hours',
                  )
                  .format('HH:mm')}
                에 다시 생겨요
              </div>
            )}
          </div>
          <TreasureHuntButton
            clickable
            text='확인'
            className='py-4 mt-6 text-[5vw]'
            onClick={() => {
              setOpenBottomSheet(false)
            }}
          />
        </div>
      </BottomSheet>
    </div>
  )
})

export default AltoonNovelDetailPage
