import { useActivity } from '@stackflow/react'
import React from 'react'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'

// import AltoonUserStore from '../../stores/AltoonUserStore'

const BottomBarComponent = () => {
  const { push } = useMyFlow()
  const activity = useActivity()

  const NavigationButton = ({ path, prop, src, label, children }) => {
    const isActive = activity.name === path

    return (
      <button
        type='button'
        className={`w-1/3 pb-[2vw] flex flex-col justify-center items-center ${
          isActive ? '' : 'text-[#BEC7D8]'
        }`}
        onClick={() => {
          if (isActive) {
            return
          }
          // 페이지 이동
          push(
            path,
            {
              prop: {
                ...prop,
              },
              component: 'BottomBar',
            },
            { animate: false },
          )
        }}
      >
        {isActive ? (
          <img
            src={`/images/toon/${src}_active.png`}
            className='w-[8vw] h-[8vw] object-cover'
            alt='active'
          />
        ) : (
          <img
            src={`/images/toon/${src}.png`}
            className='w-[8vw] h-[8vw] object-cover'
            alt='inactive'
          />
        )}
        <div
          className={`absolute bottom-[2vw] text-[3vw] ${
            isActive ? 'text-[#FF3E3E]' : ''
          }`}
        >
          {label}
        </div>
        {/* {children} */}
      </button>
    )
  }

  return (
    <div className='w-full h-[18vw] pb-[2vw] flex flex-row bg-white fixed bottom-0 z-10'>
      {/* <NavigationButton path='/' src='bt_home' label='홈' /> */}
      <NavigationButton
        path='AltoonMainPage'
        src='ic_gnb_home'
        label='홈'
        prop={{
          path: 'AltoonMainPage',
          label: '홈',
          index: 0,
        }}
      />
      <NavigationButton
        path='AltoonNovelPage'
        src='ic_gnb_novel'
        label='소설'
        prop={{
          path: 'AltoonNovelPage',
          label: '소설',
          index: 1,
        }}
      />
      <NavigationButton
        path='AltoonToonPage'
        src='ic_gnb_toon'
        label='만화'
        prop={{
          path: 'AltoonToonPage',
          label: '만화',
          index: 2,
        }}
      />
      <NavigationButton
        path='AltoonMyPage'
        src='ic_gnb_storage'
        label='보관함'
        prop={{
          path: 'AltoonMyPage',
          label: '보관함',
          index: 3,
        }}
      />
      <NavigationButton
        path='AltoonTicketPage'
        src='ic_gnb_my'
        label='내 코인'
        prop={{
          path: 'AltoonTicketPage',
          label: '내 코인',
          index: 4,
        }}
      />
    </div>
  )
}
export default BottomBarComponent
