import commaNumber from 'comma-number'

import AltoonUserStore from '../stores/AltoonUserStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis'
import useCheckCodePushVersion from '../utils/useConvertCodePushVersion'

class AltoonAction {
  async giveAltoonMissionReward({ type }) {
    const result = await backendApis.giveAltoonMissionReward({ type })
    if (result?.status === 200) {
      const goodsKOR = [
        { goods: 'ticket', goodsKOR: '열람권', unit: '개' },
        { goods: 'toonCoin', goodsKOR: '코인', unit: 'C' },
      ]
      const goodsType = result?.data?.rewardType
      const goodsObj = goodsKOR.find((item) => item.goods === goodsType)
      const goodsNameKOR = goodsObj?.goodsKOR
      const goodsUnit = goodsObj?.unit

      AltoonUserStore.set('dailyMission', result?.data?.dailyMission)

      const userData = await backendApis.loadUserData()
      if (userData?.status === 200) {
        if (userData.data?.dailyMission?.appPushInfo) {
          AltoonUserStore.set(
            'dailyMission.appPushInfo',
            userData.data.dailyMission.appPushInfo,
          )
        }
        if (userData.data?.dailyMission?.firstPurchase) {
          AltoonUserStore.set(
            'dailyMission.firstPurchase',
            userData.data.dailyMission.firstPurchase,
          )
        }
      }

      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 성공!',
          description: `미션 보상으로 ${goodsNameKOR} ${commaNumber(
            result?.data?.rewardAmount,
          )}${goodsUnit} 획득!`,
          src: `/images/toon/dailyMission/${result?.data?.rewardType}.png`,
          text: '받기',
          forceOpen: true,
        },
      })

      if (AltoonUserStore?.userData?.[`${result?.data?.rewardType}`]) {
        AltoonUserStore.increase(
          `userData.${result?.data?.rewardType}`,
          result?.data?.rewardAmount,
        )
      } else {
        AltoonUserStore.set(
          `userData.${result?.data?.rewardType}`,
          result?.data?.rewardAmount,
        )
      }
    } else if (result?.status === 202) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '중복 보상',
          description: `이미 보상을 받았어요!`,
          src: `/images/toon/warningEmoji.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else if (result?.status === 204) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 실패',
          description: `시간이 초과되었어요!`,
          src: `/images/toon/warningEmoji.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else if (result?.status === 205) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '최대 보상 도달',
          description: `미션 획득 무료 코인은 최대 10,000C까지만 보유할 수 있어요!`,
          src: `/images/toon/warningEmoji.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async linkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
  }) {
    const codePushVersionAvailable = useCheckCodePushVersion()
    const isRightCodePushVersion = codePushVersionAvailable(
      UserStore?.userInfo?.codepushVersion,
      '7.0.60',
    )
    if (!isRightCodePushVersion) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '안내',
          description: `서비스를 최신버전으로 업데이트해주세요`,
          src: `/images/toon/warningEmoji.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'linkShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
        },
      }),
    )
  }

  async kakaoLinkShare({
    shareType,
    title,
    description,
    imageUrl,
    payload,
    templateId,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'kakaoShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          payload,
          templateId,
        },
      }),
    )
  }

  showRewardedAd() {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        type: 'showRewardedAd',
        data: {
          adType: 'rewarded',
          placement: 'ALTOON_MISSION_REWARD',
          product: 'ALTOON',
        },
      }),
    )
  }
}

export default new AltoonAction()
