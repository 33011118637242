import { useState } from 'react'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const RegisterContentsSection = ({ fetchData }) => {
  const [contentType, setContentType] = useState('')
  const [isDisplayed, setIsDisplayed] = useState(true)
  const [contentInfo, setContentInfo] = useState({
    title: '',
    description: '',
    src: '',
    uniqueId: '',
    displayEndedAt: '',
    priority: 0,
  })

  const contentTypeOptions = [
    { value: 'altoon', label: '작품' },
    { value: 'banner_altoon_eventId', label: '배너_이벤트' },
    { value: 'banner_altoon_main', label: '배너_메인' },
    { value: 'tabInfo', label: '탭 정보' },
    { value: 'noticeInfo', label: '공지사항' },
  ]

  // 콘텐츠 타입별 필요한 필드 정의
  const getRequiredFields = (type) => {
    switch (type) {
      case 'altoon':
        return [
          'title',
          'description',
          'src',
          'uniqueId',
          'displayEndedAt',
          'priority',
        ]
      case 'banner_altoon_eventId':
        return [
          'title',
          'description',
          'src',
          'uniqueId',
          'displayEndedAt',
          'priority',
        ]
      case 'banner_altoon_main':
        return ['title', 'description', 'src', 'displayEndedAt', 'priority']
      case 'tabInfo':
        return ['title', 'toonIdList']
      case 'noticeInfo':
        return ['title', 'description']
      default:
        return []
    }
  }

  const handleInputChange = (field, value) => {
    setContentInfo((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleSubmit = async () => {
    try {
      const requiredFields = getRequiredFields(contentType)
      const missingFields = requiredFields.filter(
        (field) => !contentInfo[field],
      )

      if (missingFields.length > 0) {
        alert(`다음 필드를 입력해주세요: ${missingFields.join(', ')}`)
        return
      }

      // toonIdList가 문자열로 입력된 경우 배열로 변환
      const processedContentInfo = { ...contentInfo }
      if (
        contentType === 'tabInfo' &&
        typeof contentInfo?.toonIdList === 'string'
      ) {
        try {
          processedContentInfo.toonIdList = JSON.parse(contentInfo?.toonIdList)
        } catch (e) {
          alert('작품 ID 목록 형식이 올바르지 않습니다')
          return
        }
      }

      const result = await altoonCMSBackendApis.registerContentsTabInfo({
        contentType,
        isDisplayed,
        contentInfo: processedContentInfo,
      })

      if (result?.status === 200) {
        alert('등록되었습니다')
        // 폼 초기화
        setContentType('')
        setIsDisplayed(true)
        setContentInfo({
          title: '',
          description: '',
          src: '',
          uniqueId: '',
          displayEndedAt: '',
          priority: 0,
          toonIdList: '',
        })
        fetchData()
      }
    } catch (error) {
      alert(error.message || '등록 중 오류가 발생했습니다')
    }
  }

  return (
    <div className='p-4 bg-white rounded-xl'>
      <div className='py-4 font-bold text-[2.6vh]'>콘텐츠 탭 등록</div>
      <div className='grid grid-cols-2 gap-4'>
        {/* 콘텐츠 타입 선택 */}
        <div className='col-span-2'>
          <label className='block mb-2'>콘텐츠 타입</label>
          <select
            className='w-full p-2 border rounded'
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <option value=''>선택해주세요</option>
            {contentTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* 노출 여부 */}
        <div className='col-span-2'>
          <label className='block mb-2'>노출 여부</label>
          <div className='flex gap-4'>
            <label>
              <input
                type='radio'
                checked={isDisplayed}
                onChange={() => setIsDisplayed(true)}
              />{' '}
              노출
            </label>
            <label>
              <input
                type='radio'
                checked={!isDisplayed}
                onChange={() => setIsDisplayed(false)}
              />{' '}
              비노출
            </label>
          </div>
        </div>

        {/* 동적 필드 */}
        {contentType === 'altoon' && (
          <>
            <div>
              <label className='block mb-2'>제목</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>설명</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.description}
                onChange={(e) =>
                  handleInputChange('description', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>이미지 URL</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.src}
                onChange={(e) => handleInputChange('src', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>고유 ID</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.uniqueId}
                onChange={(e) => handleInputChange('uniqueId', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>노출 종료일</label>
              <input
                type='date'
                className='w-full p-2 border rounded'
                value={contentInfo.displayEndedAt}
                onChange={(e) =>
                  handleInputChange('displayEndedAt', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>우선순위</label>
              <input
                type='number'
                className='w-full p-2 border rounded'
                value={contentInfo.priority}
                onChange={(e) => handleInputChange('priority', e.target.value)}
                min='0'
              />
            </div>
          </>
        )}

        {/* 배너_이벤트 타입 필드 */}
        {contentType === 'banner_altoon_eventId' && (
          <>
            <div>
              <label className='block mb-2'>제목</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>고유 ID</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.uniqueId}
                onChange={(e) => handleInputChange('uniqueId', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>설명</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.description}
                onChange={(e) =>
                  handleInputChange('description', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>이미지 URL</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.src}
                onChange={(e) => handleInputChange('src', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>노출 종료일</label>
              <input
                type='date'
                className='w-full p-2 border rounded'
                value={contentInfo.displayEndedAt}
                onChange={(e) =>
                  handleInputChange('displayEndedAt', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>우선순위</label>
              <input
                type='number'
                className='w-full p-2 border rounded'
                value={contentInfo.priority}
                onChange={(e) => handleInputChange('priority', e.target.value)}
                min='0'
              />
            </div>
          </>
        )}

        {/* 배너_메인 타입 필드 */}
        {contentType === 'banner_altoon_main' && (
          <>
            <div>
              <label className='block mb-2'>제목</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>설명</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.description}
                onChange={(e) =>
                  handleInputChange('description', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>이미지 URL</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.src}
                onChange={(e) => handleInputChange('src', e.target.value)}
              />
            </div>

            <div>
              <label className='block mb-2'>노출 종료일</label>
              <input
                type='date'
                className='w-full p-2 border rounded'
                value={contentInfo.displayEndedAt}
                onChange={(e) =>
                  handleInputChange('displayEndedAt', e.target.value)
                }
              />
            </div>

            <div>
              <label className='block mb-2'>우선순위</label>
              <input
                type='number'
                className='w-full p-2 border rounded'
                value={contentInfo.priority}
                onChange={(e) => handleInputChange('priority', e.target.value)}
                min='0'
              />
            </div>
          </>
        )}

        {/* 탭 정보 타입 필드 */}
        {contentType === 'tabInfo' && (
          <>
            <div>
              <label className='block mb-2'>제목</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div className='col-span-2'>
              <label className='block mb-2'>
                작품 ID 목록 (예: ["id1", "id2"])
              </label>
              <textarea
                className='w-full p-2 border rounded'
                value={contentInfo?.toonIdList}
                onChange={(e) =>
                  handleInputChange('toonIdList', e.target.value)
                }
                rows={3}
              />
            </div>
          </>
        )}

        {/* 공지사항 타입 필드 */}
        {contentType === 'noticeInfo' && (
          <>
            <div>
              <label className='block mb-2'>제목</label>
              <input
                className='w-full p-2 border rounded'
                value={contentInfo.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
            </div>

            <div className='col-span-2'>
              <label className='block mb-2'>설명</label>
              <textarea
                className='w-full p-2 border rounded'
                value={contentInfo.description}
                onChange={(e) =>
                  handleInputChange('description', e.target.value)
                }
                rows={3}
              />
            </div>
          </>
        )}
      </div>

      <button
        type='button'
        className='mt-4 px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300'
        disabled={!contentType}
        onClick={handleSubmit}
      >
        등록하기
      </button>
    </div>
  )
}

export default RegisterContentsSection
