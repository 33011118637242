import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft, FiX } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import MainNovelComponent from '../components/toon/MainNovelComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import backendApis from '../utils/backendApis.ts'
import SearchBar from './SearchBar'

const AltoonSearchPage = observer(({ params }) => {
  const { pop } = useMyFlow()
  const { query } = params

  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('all') // 'all', 'toon', 'novel'

  const [toons, setToons] = useState([])
  const [novels, setNovels] = useState([])
  const [filteredResults, setFilteredResults] = useState([])

  const [recentSearches, setRecentSearches] = useState(() => {
    const saved = sessionStorage.getItem('recentSearches')
    return saved ? JSON.parse(saved) : []
  })
  const [activeSearch, setActiveSearch] = useState(() => {
    // 이전 페이지 URL 확인
    const prevPageUrl = sessionStorage.getItem('currentPageUrl')
    const isFromNovelDetail = prevPageUrl?.includes('altoon-novel-detail')

    // 현재 페이지 URL 저장
    sessionStorage.setItem('currentPageUrl', window.location.href)

    return {
      query:
        query ||
        (isFromNovelDetail && recentSearches.length > 0
          ? recentSearches[0]
          : ''),
    }
  })

  const handleSearch = (searchQuery) => {
    if (!toons && !novels) return

    const searchTerm = searchQuery.toLowerCase()

    // 웹툰과 웹소설 모두 검색
    // 웹툰 검색 결과에 contentType 추가
    const filteredToons = toons
      .filter(
        (item) =>
          item.title?.toLowerCase().includes(searchTerm) ||
          item._id?.toString().includes(searchTerm) ||
          item.providerInfo?.providerDisplayName
            ?.toLowerCase()
            .includes(searchTerm) ||
          item.author?.toLowerCase().includes(searchTerm) ||
          item.description?.toLowerCase().includes(searchTerm),
      )
      .map((item) => ({ ...item, contentType: 'toon' }))

    // 웹소설 검색 결과에 contentType 추가
    const filteredNovels = novels
      .filter(
        (item) =>
          item.title?.toLowerCase().includes(searchTerm) ||
          item._id?.toString().includes(searchTerm) ||
          item.providerInfo?.providerDisplayName
            ?.toLowerCase()
            .includes(searchTerm) ||
          item.author?.toLowerCase().includes(searchTerm) ||
          item.description?.toLowerCase().includes(searchTerm),
      )
      .map((item) => ({ ...item, contentType: 'novel' }))

    setFilteredResults([...filteredToons, ...filteredNovels])

    // 검색어 저장
    if (searchTerm) {
      const newSearches = [
        searchQuery,
        ...recentSearches.filter((s) => s !== searchQuery),
      ].slice(0, 10)
      setRecentSearches(newSearches)
      sessionStorage.setItem('recentSearches', JSON.stringify(newSearches))

      setActiveSearch({ query: '' })
    }
  }

  const clearRecentSearches = () => {
    setRecentSearches([])
    sessionStorage.removeItem('recentSearches')
  }

  const removeSearch = (searchToRemove) => {
    const newSearches = recentSearches.filter((s) => s !== searchToRemove)
    setRecentSearches(newSearches)
    sessionStorage.setItem('recentSearches', JSON.stringify(newSearches))
  }

  // 필터링된 결과 계산
  const getFilteredResultsByTab = () => {
    switch (activeTab) {
      case 'toon':
        return filteredResults.filter((item) => item.contentType === 'toon')
      case 'novel':
        return filteredResults.filter((item) => item.contentType === 'novel')
      default:
        return filteredResults
    }
  }

  const renderNoResultMessage = () => {
    switch (activeTab) {
      case 'toon':
        return (
          <div className='flex flex-col items-center justify-center h-[40vh] text-gray-500'>
            <p className='text-[4vw]'>검색된 웹툰이 없습니다.</p>
            <p className='text-[3.5vw] mt-2'>다른 검색어를 입력해보세요.</p>
          </div>
        )
      case 'novel':
        return (
          <div className='flex flex-col items-center justify-center h-[40vh] text-gray-500'>
            <p className='text-[4vw]'>검색된 웹소설이 없습니다.</p>
            <p className='text-[3.5vw] mt-2'>다른 검색어를 입력해보세요.</p>
          </div>
        )
      default:
        return (
          <div className='flex flex-col items-center justify-center h-[40vh] text-gray-500'>
            <p className='text-[4vw]'>검색 결과가 없습니다.</p>
            <p className='text-[3.5vw] mt-2'>다른 검색어를 입력해보세요.</p>
          </div>
        )
    }
  }

  // 각 탭의 결과 수 계산
  const resultCounts = {
    all: filteredResults.length,
    toon: filteredResults.filter((item) => item.contentType === 'toon').length,
    novel: filteredResults.filter((item) => item.contentType === 'novel')
      .length,
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        // 웹툰과 웹소설 데이터 동시에 가져오기
        const [toonResult, novelResult] = await Promise.all([
          backendApis.loadAllToons(),
          backendApis.loadAllNovels(),
        ])

        if (toonResult?.status === 200) {
          const allToons = toonResult.data.filter((toon) => !toon.isAdmin)
          setToons(allToons)
        }

        if (novelResult?.status === 200) {
          setNovels(novelResult.data)
        }

        // 초기 검색 실행
        handleSearch(query)
      } catch (error) {
        console.error('데이터 로딩 중 오류 발생:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (activeSearch.query === '') return
    handleSearch(activeSearch.query)
  }, [activeSearch.query])

  return (
    <AppScreen>
      <div>
        {/* 헤더 */}
        <section className='fixed top-0 w-full bg-white z-20 flex flex-row items-center'>
          <button
            type='button'
            aria-label='back'
            className='mx-2 p-2 whitespace-nowrap'
            onClick={() => pop()}
          >
            <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
          </button>
          <div className='flex-1'>
            <SearchBar
              onSearch={({ searchQuery }) => {
                setActiveSearch({ query: searchQuery })
              }}
              activeSearchQuery={activeSearch.query}
            />
          </div>
        </section>

        {/* 검색 결과 영역 */}
        <div className='mt-[18vw] px-[4vw]'>
          {/* 최근 검색어 영역 */}
          <div className='py-[4vw] mb-[4vw]'>
            <div className='flex justify-between items-center mb-[3vw]'>
              <span className='text-[3.8vw] font-bold'>최근 검색어</span>
              <button
                type='button'
                onClick={clearRecentSearches}
                className='text-[3vw] text-gray-600'
              >
                전체 삭제
              </button>
            </div>
            <div className='flex flex-wrap gap-[2vw]'>
              {recentSearches?.length > 0 &&
                recentSearches.map((search) => (
                  <div
                    key={search}
                    className='flex items-center bg-[#F6F6FA] rounded-full px-[3vw] py-[2vw]'
                  >
                    <button
                      type='button'
                      onClick={() => setActiveSearch({ query: search })}
                      className='text-[3.2vw] mr-[2vw]'
                    >
                      {search}
                    </button>
                    <button
                      type='button'
                      aria-label='remove search'
                      onClick={() => removeSearch(search)}
                      className='text-gray-400'
                    >
                      <FiX size={12} />
                    </button>
                  </div>
                ))}
              {recentSearches?.length === 0 && (
                <div className='text-gray-500 text-[3.6vw]'>
                  <p>검색 내역이 없습니다.</p>
                </div>
              )}
            </div>
          </div>

          {!isLoading && (
            <>
              {/* <div className='text-[4.6vw] font-bold mb-[2vw]'>
                검색 결과 {resultCounts[activeTab]}건
              </div> */}

              {/* 필터 탭 */}
              <div className='flex mb-[4vw] border-b border-gray-200'>
                <button
                  type='button'
                  className={`flex-1 py-[2vw] text-[4vw] font-medium ${
                    activeTab === 'all'
                      ? 'text-[#FF3E3E] border-b-2 border-[#FF3E3E]'
                      : 'text-[#BCBDC3]'
                  }`}
                  onClick={() => setActiveTab('all')}
                >
                  전체 ({resultCounts.all})
                </button>
                <button
                  type='button'
                  className={`flex-1 py-[2vw] text-[4vw] font-medium ${
                    activeTab === 'toon'
                      ? 'text-[#FF3E3E] border-b-2 border-[#FF3E3E]'
                      : 'text-[#BCBDC3]'
                  }`}
                  onClick={() => setActiveTab('toon')}
                >
                  웹툰 ({resultCounts.toon})
                </button>
                <button
                  type='button'
                  className={`flex-1 py-[2vw] text-[4vw] font-medium ${
                    activeTab === 'novel'
                      ? 'text-[#FF3E3E] border-b-2 border-[#FF3E3E]'
                      : 'text-[#BCBDC3]'
                  }`}
                  onClick={() => setActiveTab('novel')}
                >
                  웹소설 ({resultCounts.novel})
                </button>
              </div>

              {getFilteredResultsByTab().length > 0 ? (
                <div className='grid grid-cols-2 gap-[3vw]'>
                  {getFilteredResultsByTab().map((item, index) =>
                    item.contentType === 'toon' ? (
                      <MainToonComponent
                        key={item?._id}
                        toon={item}
                        index={index}
                        type='genre'
                        component='search_result'
                      />
                    ) : (
                      <MainNovelComponent
                        key={item?._id}
                        novel={item}
                        index={index}
                        type='search_result'
                        component='search_result'
                      />
                    ),
                  )}
                </div>
              ) : (
                renderNoResultMessage()
              )}
            </>
          )}
          {isLoading && (
            <div>
              <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
              <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
                <ClipLoader
                  color='#ff3e3e'
                  loading={isLoading}
                  size={80}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </AppScreen>
  )
})

export default AltoonSearchPage
