import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import backendApis from '../utils/backendApis'
import resize from '../utils/resize'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonEventListPage = observer(() => {
  const pathMapper = {
    '/altoon-reader': 'AltoonReaderPage',
    '/altoon-novel-reader': 'AltoonNovelReaderPage',
    '/altoon-item': 'AltoonItemPage',
    '/altoon-event-page': 'AltoonEventPage',
    '/altoon-main-event-page': 'AltoonMainEventPage',
  }

  const { pop, push } = useMyFlow()
  const [isLoading, setIsLoading] = useState(false)
  const [bannerData, setBannerData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const bannerData = await backendApis.loadBannerData()
      if (bannerData?.status === 200) {
        const filteredBannerData = bannerData?.data?.filter(
          (banner) => !banner?.bannerType,
        )
        setBannerData(filteredBannerData)
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonEventListPage', 'enteredScreen', '')
    setIsLoading(false)
  }, [])

  const handleBannerClick = (data, index) => {
    backendApis.logToonAction('AltoonMainBanner', 'clickedBanner', {
      toonInfo: data?.state?.toonInfo?._id,
      title: data?.state?.toonInfo?.title,
    })
    // URL에서 pathname과 쿼리 파라미터 분리
    const queryString = data?.pathname?.split('?')
    const searchParams = new URLSearchParams(queryString[1])
    const eventId = searchParams.get('eventId') || ''
    const itemId = searchParams.get('itemId') || ''

    push(pathMapper[queryString[0]], {
      state: {
        ...data?.state,
      },
      ...(eventId && { eventId }),
      ...(itemId && { itemId }),
      component: 'bannerList',
      index,
    })
  }

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-10 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>이벤트</div>
        <div className='w-[16vw]' />
      </section>
    )
  }

  const MainImageSection = () => {
    return (
      <div>
        {bannerData?.map((banner, index) => {
          return (
            <button
              key={index || Math.random()}
              type='button'
              onClick={() => {
                handleBannerClick(banner?.navigateTo, index)
              }}
            >
              <img
                className='w-full h-auto py-[2vw]'
                src={resize(banner?.imageUri, 1230, 825)}
                alt='banner'
              />
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <AppScreen>
      <div>
        <HeaderComponent />
        <div className='pt-[10vw]'>
          <MainImageSection />
        </div>
        {isLoading && <LoadingIndicator isLoading={isLoading} />}
      </div>
    </AppScreen>
  )
})

export default AltoonEventListPage
