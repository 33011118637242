import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import ComponentTitle from '../components/atoms/ComponentTitle'
import BottomBarComponent from '../components/toon/BottomBarComponent'
import BannerSwiperComponent from '../components/toon/bannerSwiperComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis'

const AltoonToonPage = observer(() => {
  const scrollContainerRef = useRef(null)
  const tabRef = useRef(null)
  const initialTabOffsetTop = useRef(null) // Store initial offset
  const recommendRef = useRef(null)
  const dailyRef = useRef(null)

  const { pop, push } = useMyFlow()

  const token = useSearchParam('token')

  let isCancelled = false

  const [isLoading, setIsLoading] = useState(false)
  const [toons, setToons] = useState([])
  const [bannerData, setBannerData] = useState()
  const [recommendingToons, setRecommendingToons] = useState([])
  const [indicatorStyle, setIndicatorStyle] = useState({})
  const [selectedTab, setSelectedTab] = useState(
    window.localStorage.getItem('toon_default_tab') || 'daily',
  )
  const [todayWeekday, setTodayWeekday] = useState(
    sessionStorage.getItem('toon_default_weekday') || moment().format('ddd'),
  )

  useEffect(() => {
    let activeRef
    switch (selectedTab) {
      case 'recommend':
        activeRef = recommendRef
        break
      case 'daily':
        activeRef = dailyRef
        break
      default:
        break
    }

    if (activeRef && activeRef.current) {
      const rect = activeRef.current.getBoundingClientRect()
      setIndicatorStyle({
        width: rect.width + 10,
        transform: `translateX(${activeRef.current.offsetLeft}px)`,
        zIndex: 0,
      })
    }
  }, [selectedTab])

  useEffect(() => {
    const handleResize = () => {
      if (tabRef.current.style.position === 'fixed') {
        tabRef.current.style.width = `${scrollContainerRef.current.offsetWidth}px`
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchToonData = async () => {
      // 유저정보 가져오기
      const userData = await backendApis.loadUserData()
      if (userData?.status === 200) {
        AltoonUserStore.set('userData', userData?.data)
        if (userData.data?.dailyMission?.appPushInfo) {
          AltoonUserStore.set(
            'dailyMission.appPushInfo',
            userData.data.dailyMission.appPushInfo,
          )
        }
        if (userData.data?.dailyMission?.firstPurchase) {
          AltoonUserStore.set(
            'dailyMission.firstPurchase',
            userData.data.dailyMission.firstPurchase,
          )
        }

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        const showModalSequentially = async (initModalList) => {
          for (const init of initModalList) {
            await delay(800)
            if (isCancelled) break
            THModalStore.setType(init)
          }
          if (!isCancelled) {
            await backendApis.setToonInitModal({ initModalList: [] })
          }
        }

        if (userData?.data?.initModalList) {
          showModalSequentially(userData.data.initModalList)
        }
      }

      // 작품 정보 가져오기
      const toonResult = await backendApis.loadAllToons()
      if (toonResult?.status === 200) {
        // deletedAt이 없는 모든 작품 가져와서 isAdmin인 작품을 거름
        setToons(toonResult.data.filter((toon) => !toon.isAdmin))
        if (AltoonUserStore.userData?.isAdmin === true) {
          // 어드민 계정이면 맨 뒤에 어드민 작품을 붙여줌
          setToons([
            ...toonResult.data.filter((toon) => !toon.isAdmin),
            ...toonResult.data.filter((toon) => toon.isAdmin),
          ])
        }

        const newToonLog = await backendApis.loadRecommendingToons(
          'hot',
          'toonPick',
        )

        if (newToonLog?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const newToonList = newToonLog.data.map((toon) => {
            const toonDetails = toonMap.get(toon.toonId)

            return {
              ...toon, // 트렌딩 만화의 기존 데이터
              toonDetails, // 매핑된 만화의 상세 정보
            }
          })

          setRecommendingToons(newToonList)
        }

        await backendApis.logToonAction('AltoonToonPage', 'enteredScreen', '')
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: true,
      }),
    )
    fetchToonData()

    return () => {
      isCancelled = true // 컴포넌트 언마운트 또는 useEffect 재실행 시 현재 진행 중인 작업 취소
    }
  }, [])

  useEffect(() => {
    const fetchBannerData = async () => {
      // 배너 정보 가져오기
      const bannerData = await backendApis.loadBannerData()
      if (bannerData?.status === 200) {
        const filteredBannerData = bannerData?.data?.filter(
          (banner) =>
            banner?.bannerType === `${selectedTab}_banner` &&
            banner?.itemId?.toString() !== '65e0160e3401440ed9cecde3',
        )

        setBannerData(filteredBannerData)
      }
    }
    fetchBannerData()
  }, [selectedTab])

  useEffect(() => {
    // 배너 데이터 로드 후 offsetTop 설정
    if (bannerData && tabRef.current) {
      initialTabOffsetTop.current = tabRef.current.offsetTop
    }
  }, [bannerData]) // bannerData가 변경될 때마다 실행

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current

    if (scrollContainer && tabRef.current) {
      initialTabOffsetTop.current = tabRef.current.offsetTop
    }

    const handleScroll = () => {
      if (scrollContainerRef.current && tabRef.current) {
        const scrollPosition = scrollContainerRef.current.scrollTop

        if (scrollPosition >= initialTabOffsetTop.current) {
          tabRef.current.style.position = 'fixed'
          tabRef.current.style.top = '0'
          tabRef.current.style.width = `${scrollContainerRef.current.offsetWidth}px`
          tabRef.current.style.zIndex = '1000'
        } else {
          tabRef.current.style.position = 'relative'
          tabRef.current.style.top = 'auto'
          tabRef.current.style.width = '100%'
          tabRef.current.style.zIndex = '10'
        }
      }
    }

    if (scrollContainer && tabRef.current) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer && tabRef.current) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='flex font-bold'>만화</div>
        <button
          type='button'
          className='px-[4vw] text-[3.6vw]'
          onClick={() => {
            push('AltoonSearchPage', {
              query: '',
              component: 'toon_search',
            })
          }}
        >
          <img
            src='/images/toon/searchIcon.png'
            alt='share'
            className='w-[6vw] h-[6vw]'
          />
        </button>
      </section>
    )
  }

  const AltoonRecommendComponent = () => {
    return (
      <div className='ml-[4vw] my-[4vw] font-semibold'>
        <ComponentTitle text='올툰 Pick!' />
        <div className='flexRow justify-start overflow-x-scroll py-[2vw] font-normal'>
          {recommendingToons?.slice(0, 3).map((toon, index) => (
            <MainToonComponent
              key={toon?._id}
              toon={toon}
              index={index}
              type='altoonRecommend'
              component='toon_altoonRecommend'
            />
          ))}
        </div>
      </div>
    )
  }

  const getTopToggleButtonStyle = (tab) => {
    return `flex-1 py-2 text-sm font-bold relative z-10 ${
      tab === selectedTab ? 'text-white' : 'text-[#9C9DA4]'
    }`
  }

  const ToonListByWeekday = () => {
    const getButtonStyle = (weekday) => {
      if (weekday === todayWeekday) {
        return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] font-bold text-white bg-[#FF5951] rounded-full' // 선택된 장르일 때의 스타일
      }
      return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] text-[#BCBDC3] bg-[#F6F6FA] rounded-full' // 기본 스타일
    }

    const weekdayFilters = [
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sun',
      'Free',
    ].reduce((acc, day) => {
      acc[day] = () => toons.filter((toon) => toon?.weekday?.includes(day))
      return acc
    }, {})

    const filterFunction =
      weekdayFilters[todayWeekday] || weekdayFilters.defaultFilter
    const filteredToons = filterFunction()
    const isToday = todayWeekday === moment().format('ddd')

    const handleWeekdayChange = (weekday) => {
      sessionStorage.setItem('toon_default_weekday', weekday)
      setTodayWeekday(weekday)

      // 스크롤 위치 조정 (기존)
      const scrollPosition = scrollContainerRef.current.scrollTop
      if (scrollPosition >= initialTabOffsetTop.current) {
        const offsetAdjustment = 10
        scrollContainerRef.current.scrollTop =
          initialTabOffsetTop.current - offsetAdjustment
      }

      // 자유연재 선택시 가로 스크롤 맨 오른쪽으로 이동
      if (weekday === 'Free' && tabRef.current) {
        setTimeout(() => {
          tabRef.current.scrollTo({
            left: tabRef.current.scrollWidth,
            behavior: 'smooth',
          })
        }, 0)
      }
    }

    return (
      <div className='flex flex-col items-center overflow-y-scroll'>
        <div
          ref={tabRef}
          className='w-full flex flex-row justify-between px-[4vw] py-[4vw] text-[3.6vw] bg-white relative overflow-x-auto whitespace-nowrap'
        >
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Free'].map(
            (day, index) => (
              <button
                key={day}
                type='button'
                className={getButtonStyle(day)}
                onClick={() => handleWeekdayChange(day)}
              >
                {[...['월', '화', '수', '목', '금', '토', '일'], '자유'][index]}
              </button>
            ),
          )}
        </div>

        <div className='px-4'>
          {filteredToons?.map((toon, index) => (
            <MainToonComponent
              key={toon?._id}
              toon={toon}
              index={index}
              isToday={isToday}
              component='toon_daily'
            />
          ))}
        </div>
      </div>
    )
  }

  const ToonListByGenre = () => {
    const [selectedGenre, setSelectedGenre] = useState('hot')

    const hotRef = useRef(null)
    const romanceRef = useRef(null)
    const dailyRef = useRef(null)
    const romanceFantasyRef = useRef(null)
    const fantasyRef = useRef(null)
    const allRef = useRef(null)

    const genreFilters = {
      all: () => toons.filter((toon) => !toon.weekday),
      'daily/drama/thriller': () =>
        toons.filter(
          (toon) =>
            !toon.weekday &&
            (toon.genre === 'daily' ||
              toon.genre === 'drama' ||
              toon.genre === 'thriller'),
        ),
      hot: () =>
        toons.filter((toon) => !toon.weekday && toon.tagList?.hot === true),
      defaultFilter: () =>
        toons.filter((toon) => !toon.weekday && toon.genre === selectedGenre),
    }

    const filterFunction =
      genreFilters[selectedGenre] || genreFilters.defaultFilter
    const filteredToons = filterFunction()

    const getButtonStyle = (genre) => {
      if (genre === selectedGenre) {
        return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] font-bold text-white bg-[#FF5951] rounded-full' // 선택된 장르일 때의 스타일
      }
      return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] text-[#BCBDC3] bg-[#F6F6FA] rounded-full' // 기본 스타일
    }

    const handleGenreChange = (genre) => {
      sessionStorage.setItem('toon_default_genre', genre)
      setSelectedGenre(genre)
      const scrollPosition = scrollContainerRef.current.scrollTop

      if (scrollPosition >= initialTabOffsetTop.current) {
        const offsetAdjustment = 10 // 원하는 만큼의 값을 빼서 스크롤 위치를 조정합니다.
        scrollContainerRef.current.scrollTop =
          initialTabOffsetTop.current - offsetAdjustment
      }
    }
    return (
      <div className='flex flex-col items-center overflow-y-scroll'>
        <div
          ref={tabRef}
          className='w-full flex flex-row justify-between px-[4vw] py-[4vw] text-[3.6vw] bg-white relative overflow-x-scroll whitespace-nowrap'
        >
          <button
            ref={hotRef}
            type='button'
            className={getButtonStyle('hot')}
            onClick={() => {
              handleGenreChange('hot')
            }}
          >
            인기
          </button>
          <button
            ref={romanceRef}
            type='button'
            className={getButtonStyle('romance')}
            onClick={() => {
              handleGenreChange('romance')
            }}
          >
            로맨스
          </button>
          <button
            ref={romanceFantasyRef}
            type='button'
            className={getButtonStyle('romanceFantasy')}
            onClick={() => {
              handleGenreChange('romanceFantasy')
            }}
          >
            로맨스판타지
          </button>
          <button
            ref={fantasyRef}
            type='button'
            className={getButtonStyle('fantasy')}
            onClick={() => {
              handleGenreChange('fantasy')
            }}
          >
            판타지
          </button>
          <button
            ref={dailyRef}
            type='button'
            className={getButtonStyle('daily/drama/thriller')}
            onClick={() => {
              handleGenreChange('daily/drama/thriller')
            }}
          >
            일상/드라마
          </button>
          <button
            ref={allRef}
            type='button'
            className={getButtonStyle('all')}
            onClick={() => {
              handleGenreChange('all')
            }}
          >
            전체
          </button>
        </div>

        <div className='w-full grid grid-cols-2 gap-[2vw] px-[4vw]'>
          {filteredToons?.map((toon, index) => (
            <MainToonComponent
              key={toon?._id}
              toon={toon}
              index={index}
              type='genre'
              component='toon_genre'
            />
          ))}
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className='w-full overflow-y-scroll'
        style={{ height: '100vh' }}
        ref={scrollContainerRef}
      >
        <HeaderComponent />
        <div className='flex flex-col items-center justify-center'>
          <div className='relative flex w-[88vw] mb-[4vw] bg-[#F6F6FA] rounded-full'>
            <button
              type='button'
              ref={dailyRef}
              className={getTopToggleButtonStyle('daily')}
              onClick={() => {
                setSelectedTab('daily')
                window.localStorage.setItem('toon_default_tab', 'daily')
              }}
            >
              요일
            </button>
            <button
              type='button'
              ref={recommendRef}
              className={getTopToggleButtonStyle('recommend')}
              onClick={() => {
                setSelectedTab('recommend')
                window.localStorage.setItem('toon_default_tab', 'recommend')
              }}
            >
              완결
            </button>
            <div
              style={indicatorStyle}
              className='absolute left-0 top-0 bottom-0 bg-[#FF3E3E] rounded-full transition-transform duration-300 ease-in-out'
            />
          </div>
        </div>

        {bannerData?.length > 0 && (
          <div className='w-[92vw] ml-[4vw]'>
            <BannerSwiperComponent bannerData={bannerData} bannerType='toon' />
          </div>
        )}
        {!bannerData && (
          <div className='w-[92vw] ml-[4vw]'>
            <BannerSwiperComponent empty bannerType='toon' />
          </div>
        )}

        {selectedTab === 'daily' && <ToonListByWeekday />}
        {selectedTab === 'recommend' && <AltoonRecommendComponent />}
        {selectedTab === 'recommend' && <ToonListByGenre />}

        <div className='h-[20vw]' />
        <BottomBarComponent />
        {isLoading && <LoadingIndicator />}
      </div>
    </AppScreen>
  )
})

export default AltoonToonPage
