import { AppScreen } from '@stackflow/plugin-basic-ui'
import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import AltoonAction from '../actions/AltoonAction'
import LoadingIndicator from '../components/atoms/loadingIndicator'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis.ts'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonEventPage = observer(({ params }) => {
  const eventId = params.eventId
  const isFrom = params.isFrom

  const { pop, push } = useMyFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [eventInfo, setEventInfo] = useState()
  const [toonsInfo, setToonsInfo] = useState()
  const [novelsInfo, setNovelsInfo] = useState()
  const [participated, setParticipated] = useState(false)
  const [leaderboardInfo, setLeaderboardInfo] = useState()
  const getDayOfWeek = (date) => {
    const days = ['일', '월', '화', '수', '목', '금', '토']
    return days[new Date(date).getDay()]
  }
  const formatDateToKorean = (date) => {
    const d = new Date(date)
    const today = new Date()

    // 날짜가 오늘인지 확인
    if (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    ) {
      return '오늘'
    }

    const days = [
      '일요일',
      '월요일',
      '화요일',
      '수요일',
      '목요일',
      '금요일',
      '토요일',
    ]

    return `${d.getMonth() + 1}월 ${d.getDate()}일 ${days[d.getDay()]}`
  }

  useEffect(() => {
    const fetchData = async () => {
      if (eventId === 'none') {
        return
      }

      const eventInfo = await backendApis.loadToonEventInfo(eventId)
      if (eventInfo?.status === 200) {
        setEventInfo(eventInfo?.data)
        if (eventInfo?.data?.toonIdList?.length > 0) {
          const toonInfo = await backendApis.loadToonInfoByIds(
            eventInfo?.data?.toonIdList,
          )
          setToonsInfo(toonInfo?.data)
        }
        if (eventInfo?.data?.novelIdList?.length > 0) {
          const novelInfo = await backendApis.loadNovelInfoByIds(
            eventInfo?.data?.novelIdList,
          )
          setNovelsInfo(novelInfo?.data)
        }

        if (eventInfo?.data?.eventType === 'leaderboard') {
          const leaderboardInfo = await backendApis.loadLeaderboardInfo(eventId)
          setLeaderboardInfo(leaderboardInfo?.data?.slice(0, 10))

          if (
            leaderboardInfo?.data?.find(
              (e) =>
                e?.userId.toString() === UserStore?.userInfo?._id.toString(),
            )
          ) {
            setParticipated(true)
          }
        }
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonEventPage', 'enteredScreen', '')
    setIsLoading(false)
  }, [participated])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>
          {eventInfo?.eventTitle?.length > 20
            ? `${eventInfo?.eventTitle?.slice(0, 20)}..`
            : eventInfo?.eventTitle}
        </div>
        <button
          type='button'
          className='px-[4vw] text-[3.6vw]'
          onClick={() => {
            backendApis.logToonAction('AltoonEventPage', 'linkShare', '')

            AltoonAction.linkShare({
              shareType: 'Altoon_eventShare',
              title: `[올툰 이벤트]`,
              description: `[${eventInfo?.eventTitle}] 이벤트가 진행중이에요`,
              imageUrl: eventInfo?.bannerImageUri ?? '',
              payload: {
                eventId,
              },
            })
          }}
        >
          <img
            src='/images/toon/shareIcon.png'
            alt='share'
            className='w-[5vw] h-[5vw]'
          />
        </button>
      </section>
    )
  }

  const MainImageSection = () => {
    if (!eventInfo?.eventImageUri) {
      return
    }
    return (
      <div className={isFrom !== 'AltoonMainEventPage' ? 'pt-[16vw]' : ''}>
        <img
          src={eventInfo?.eventImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const SubImageSection = () => {
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.subImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const DramaPosterSection = ({
    dramaId = '66445534573d90c7474a0bb3',
    posterImage = 'https://assets.ilevit.com/404e45eb-8a6c-47e1-bd0f-87081285747d.png',
  }) => {
    return (
      <button
        type='button'
        aria-label='dramaPoster'
        className='w-full h-full pb-[10vw]'
        onClick={() => {
          backendApis.logToonAction('AltoonMainBanner', 'clickedDramaPoster', {
            dramaId,
          })
          const webUrl = 'https://alwayz-drama-front.ilevit.com'
          const redirectSearchParams = new URLSearchParams({
            token: AuthStore.token,
            randomString: Math.random().toString(36).slice(2, 10),
          })
          const prop = {
            screen: 'Drama',
            prop: {
              eventPageMapping: {
                redirectUrl: `${webUrl}/?${redirectSearchParams}`,
              },
              isFrom: 'Altoon',
              dramaId,
            },
          }
          window.location.href = `#navigate.${JSON.stringify(prop)}`
        }}
      >
        <img src={posterImage} alt='' className='w-full h-full object-cover' />
      </button>
    )
  }

  const CTAButtonOne = () => {
    return (
      <div className='w-full py-[4vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaOneInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaOneInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaOneInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaOneInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaOneInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
                pop()
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaOneInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaOneInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaOneInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const CTAButtonTwo = () => {
    return (
      <div className='w-full pt-[4vw] pb-[10vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaTwoInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaTwoInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaTwoInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaTwoInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaTwoInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            } else {
              // navigate(
              //   `/${eventInfo?.ctaTwoInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaTwoInfo?.ctaProp}`,
              // )
              pop()
            }
          }}
        >
          {eventInfo?.ctaTwoInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const ToonsAndNovelsList = () => {
    return (
      <div className='pb-4'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          {eventInfo?.toonListTitle || '이벤트 대상 작품목록'}
        </div>
        {novelsInfo?.length > 0 &&
          novelsInfo?.map((novel, index) => (
            <div
              key={novel?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainNovelComponent
                novel={novel}
                index={index}
                type='eventPage'
              />
            </div>
          ))}
        {toonsInfo?.length > 0 &&
          toonsInfo?.map((toon, index) => (
            <div
              key={toon?._id}
              className='mx-4 mb-4 rounded-lg border bg-white'
            >
              <MainToonComponent
                toon={toon}
                index={index}
                type='eventPage'
                component='eventPage_toon'
              />
            </div>
          ))}
      </div>
    )
  }

  const PollList = () => {
    const [selectedToonId, setSelectedToonId] = useState()
    const [currentPage, setCurrentPage] = useState(() => {
      const savedPage = window.localStorage.getItem('pollCurrentPage')
      return savedPage ? parseInt(savedPage, 10) : 0
    })
    const [myPollData, setMyPollData] = useState()
    const [isPollSubmitted, setIsPollSubmitted] = useState(
      moment(window.localStorage.getItem('pollSubmitted')).format(
        'YYYY-MM-DD',
      ) === moment().format('YYYY-MM-DD'),
    )
    const getPageCount = (items) => Math.ceil(items.length / 4)
    const shuffleArray = (array, seed) => {
      const shuffled = [...array]

      // 시드 기반 난수 생성기
      const seededRandom = () => {
        const x = Math.sin(seed++) * 10000
        return x - Math.floor(x)
      }

      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(seededRandom() * (i + 1))
        ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
      }

      return shuffled
    }

    const getShuffledToonsList = () => {
      // toonsInfo가 없거나 비어있으면 빈 배열 반환
      if (!toonsInfo || toonsInfo.length === 0) {
        return []
      }

      const today = moment().format('YYYY-MM-DD')
      const storedData = window.localStorage.getItem(
        `shuffledToons_${moment().format('DD')}`,
      )

      // 깊은 복사 함수
      const deepClone = (obj) => {
        try {
          return JSON.parse(JSON.stringify(obj))
        } catch (e) {
          console.error('Cloning error:', e)
          return obj
        }
      }

      // localStorage에서 데이터를 불러올 때
      if (storedData) {
        try {
          const { date, toons } = JSON.parse(storedData)
          if (date === today && toons && toons.length > 0) {
            return deepClone(toons)
          }
        } catch (e) {
          window.localStorage.removeItem('shuffledToonsList')
        }
      }

      // 새로운 셔플 데이터 생성
      const toonsToShuffle = deepClone(toonsInfo)
      const shuffled = shuffleArray(toonsToShuffle, getTodaySeed())

      if (shuffled && shuffled.length > 0) {
        window.localStorage.setItem(
          'shuffledToonsList',
          JSON.stringify({
            date: today,
            toons: shuffled,
          }),
        )
      }

      return shuffled
    }

    // 오늘 날짜를 시드로 사용
    const getTodaySeed = () => {
      const today = new Date()
      return (
        today.getFullYear() * 10000 +
        (today.getMonth() + 1) * 100 +
        today.getDate()
      )
    }

    const shuffledToonsInfo = useMemo(() => {
      return getShuffledToonsList()
    }, [toonsInfo])

    useEffect(() => {
      const fetchPollData = async () => {
        const pollData = await backendApis.loadPollData(eventInfo?._id)
        if (pollData?.status === 200) {
          setMyPollData(pollData?.data)
        }
      }

      fetchPollData()
    }, [isPollSubmitted])

    // 페이지 변경 시 로컬스토리지에 저장하는 함수
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage)
      window.localStorage.setItem('pollCurrentPage', newPage.toString())
    }

    const containerRef = useRef(null)
    const isScrolling = useRef(false)

    // 컴포넌트 마운트 또는 currentPage 변경 시 저장된 페이지로 스크롤
    useEffect(() => {
      const scrollToSavedPage = () => {
        if (containerRef.current && currentPage > 0) {
          const targetScroll = currentPage * window.innerWidth
          containerRef.current.scrollTo({
            left: targetScroll,
            behavior: 'instant', // 초기 스크롤은 즉시 이동
          })
        }
      }

      // DOM이 완전히 렌더링된 후 스크롤 실행
      const timer = setTimeout(scrollToSavedPage, 100)
      return () => clearTimeout(timer)
    }, [currentPage])

    const handleScroll = (e) => {
      if (isScrolling.current) return

      const container = e.target
      const scrollPosition = container.scrollLeft
      const pageWidth = window.innerWidth
      const newPage = Math.round(scrollPosition / pageWidth)

      if (newPage !== currentPage) {
        isScrolling.current = true
        const targetScroll = newPage * pageWidth

        container.scrollTo({
          left: targetScroll,
          behavior: 'smooth',
        })

        handlePageChange(newPage)

        setTimeout(() => {
          isScrolling.current = false
        }, 100)
      }
    }

    return (
      <div className='pb-10'>
        <div
          className='w-[100vw] h-[100vw] flex flex-row justify-around items-center px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'
          style={{
            backgroundImage: `url('https://assets.ilevit.com/71ebe9e1-1f3a-435a-8670-7dfb641c9329.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className='w-[40vw] py-[4vw] rounded-lg text-center bg-black'>
            <div className='text-[3.8vw]'>나의 투표 횟수</div>
            <div className='text-[6vw]'>{myPollData?.length || 0}번</div>
          </div>
          <div className='w-[40vw] py-[4vw] rounded-lg text-center bg-black'>
            <div className='text-[3.8vw]'>받은 코인</div>
            <div className='text-[6vw]'>
              {myPollData?.length > 15 ? '450' : (myPollData?.length || 0) * 10}
              C
            </div>
          </div>
        </div>

        <div className='relative'>
          <div
            ref={containerRef}
            className='overflow-x-auto scrollbar-hide'
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch',
              scrollBehavior: 'smooth',
            }}
            onScroll={handleScroll}
          >
            <div className='inline-flex'>
              {Array(getPageCount(toonsInfo || []))
                .fill()
                .map((_, pageIndex) => (
                  <div
                    key={`page-${pageIndex}`}
                    className='w-screen flex-shrink-0'
                    style={{
                      scrollSnapAlign: 'center',
                      scrollSnapStop: 'always',
                    }}
                  >
                    <div className='grid grid-cols-2 gap-4 px-4'>
                      {shuffledToonsInfo
                        ?.slice(pageIndex * 4, (pageIndex + 1) * 4)
                        .map((toon, index) => (
                          <button
                            type='button'
                            key={toon?._id}
                            className={`relative flex flex-col items-center rounded-lg border bg-white ${
                              selectedToonId === toon?._id
                                ? 'border-red-500 border-[1vw]'
                                : ''
                            }`}
                            onClick={() => {
                              // 현재 선택된 작품이 있는 페이지 찾기
                              const itemsPerPage = 4
                              const toonIndex = shuffledToonsInfo?.findIndex(
                                (t) => t._id === toon._id,
                              )
                              const page = Math.floor(toonIndex / itemsPerPage)

                              // 페이지 정보 저장
                              window.localStorage.setItem(
                                'pollCurrentPage',
                                page.toString(),
                              )
                              setCurrentPage(page)
                              setSelectedToonId(toon?._id)
                            }}
                          >
                            <img
                              src={
                                selectedToonId === toon?._id
                                  ? '/images/toon/checkRed.png'
                                  : '/images/toon/checkGray.png'
                              }
                              alt='poll'
                              className={`absolute top-[1vw] right-[1vw] z-10 ${
                                selectedToonId === toon?._id
                                  ? 'w-[9vw] h-[9vw]'
                                  : 'w-[10vw] h-[10vw]'
                              }`}
                            />
                            <MainToonComponent
                              toon={toon}
                              index={index}
                              type='pollEvent'
                              component='pollEventPage_toon'
                            />
                          </button>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Dot Indicator */}
          <div className='flex justify-center gap-2 mt-4'>
            {Array(getPageCount(toonsInfo || []))
              .fill()
              .map((_, index) => {
                const pageToonsKey = shuffledToonsInfo
                  ?.slice(index * 4, (index + 1) * 4)
                  .map((toon) => toon._id)
                  .join('-')
                return (
                  <div
                    key={`dot-${pageToonsKey}`}
                    className={`w-2 h-2 rounded-full ${
                      currentPage === index ? 'bg-[#FF3E3E]' : 'bg-gray-300'
                    }`}
                  />
                )
              })}
          </div>
        </div>
        <div className='px-4 mt-6'>
          <button
            type='button'
            className={
              selectedToonId && !isPollSubmitted
                ? 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
                : 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
            }
            disabled={isPollSubmitted}
            onClick={async () => {
              if (!selectedToonId) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🎟️',
                  message: '투표할 작품을 선택해주세요!',
                  duration: 2000,
                })
              }
              if (selectedToonId) {
                const result = await backendApis.submitPoll(
                  eventInfo?._id,
                  selectedToonId,
                )
                if (result?.status === 200) {
                  ToastStore.toastOn({
                    type: 'success',
                    message: '투표가 완료되었어요!',
                    duration: 2000,
                  })
                  window.localStorage.setItem('pollSubmitted', new Date())
                  setIsPollSubmitted(true)
                  setSelectedToonId(null)
                } else if (result?.status === 204) {
                  ToastStore.toastOn({
                    type: 'error',
                    message: '오늘 이미 투표하셨어요!',
                    duration: 2000,
                  })
                } else {
                  ToastStore.toastOn({
                    type: 'error',
                    message:
                      '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                    duration: 2000,
                  })
                }
              }
            }}
          >
            {isPollSubmitted ? '오늘 투표 완료' : '투표하기'}
          </button>
        </div>
      </div>
    )
  }

  const LeaderboardSection = () => {
    return (
      <div className='pb-4' style={{ backgroundColor: '#151517' }}>
        <img
          src='https://assets.ilevit.com/e8d82daa-22ce-48ac-8aba-e1da128405a7.png'
          alt='leaderboard'
          className='w-full h-full object-cover'
        />
        <div className='flex flex-col justify-center items-center mx-[4vw] mb-[4vw] pt-6 pb-4 font-bold text-[3.8vw] bg-white text-center rounded-lg '>
          내 순위
          <div
            className={
              participated
                ? 'w-full text-[6vw] text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw] '
                : 'w-full text-[4.2vw] text-gray-500 text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw]'
            }
          >
            {participated
              ? `${
                  (leaderboardInfo?.find(
                    (e) =>
                      e?.userId.toString() ===
                      UserStore?.userInfo?._id.toString(),
                  )?.rank > 98
                    ? '+99'
                    : leaderboardInfo?.find(
                        (e) =>
                          e?.userId.toString() ===
                          UserStore?.userInfo?._id.toString(),
                      )?.rank) || '- '
                }
            위`
              : '이벤트 참여 전입니다'}
          </div>
        </div>
        <div className='bg-white rounded-lg mx-4 p-4'>
          <div className='mb-[2vw] text-[3.6vw] font-bold text-start'>
            {new Date().getDate()}일 {new Date().getHours()}시 기준
          </div>
          <div className='flex flex-row justify-between px-4 py-3 text-[3.6vw] font-bold text-center text-gray-600 border-b border-gray-100'>
            <div className='w-[10vw]'>순위</div>
            <div className='w-[50vw]'>닉네임</div>
            <div className='w-[20vw]'>읽은 회차</div>
          </div>
          {[...Array(10)].map((_, index) => {
            const leaderboard = leaderboardInfo?.[index]

            return (
              <div
                key={leaderboard?._id || `empty-${index}`}
                className='flex flex-row justify-between px-4 py-3 text-[3.4vw] border-b border-gray-100'
              >
                <div className='w-[10vw] text-center'>{index + 1}</div>
                <div
                  className={
                    index + 1 < 4
                      ? 'font-bold w-[50vw] text-center'
                      : 'w-[50vw] text-center'
                  }
                >
                  {leaderboard
                    ? `${
                        leaderboard?.userName?.length > 10
                          ? `${leaderboard?.userName?.slice(0, 10)}..`
                          : leaderboard?.userName
                      } (${leaderboard?.phoneNumber?.slice(7, 11) || '****'})`
                    : '-'}
                </div>
                <div className='w-[20vw] text-center text-[#FF3E3E] font-bold'>
                  {leaderboard ? commaNumber(leaderboard.count) || 0 : '-'}
                </div>
              </div>
            )
          })}
        </div>
        <div className='px-4 mt-6 pb-[20vw]'>
          <button
            type='button'
            className={
              participated
                ? 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
                : 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
            }
            disabled={participated}
            onClick={async () => {
              const result = await backendApis.participateLeaderboard(
                eventInfo?._id,
              )

              if (result?.status === 200) {
                setParticipated(true)
              } else if (result?.status === 204) {
                ToastStore.toastOn({
                  type: 'error',
                  message: '이미 참여중이에요!',
                  duration: 2000,
                })
                setParticipated(true)
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            }}
          >
            {participated ? '참여 완료' : '이벤트 참여하기'}
          </button>
        </div>
      </div>
    )
  }

  const TimerSection = () => {
    const [timeLeft, setTimeLeft] = useState({
      hours: '00',
      minutes: '00',
      seconds: '00',
    })

    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date()
        const currentHour = now.getHours()

        const targetTime = new Date(now)
        if (currentHour < 22) {
          targetTime.setHours(22, 0, 0, 0)
        } else {
          targetTime.setDate(targetTime.getDate() + 1)
          targetTime.setHours(0, 0, 0, 0)
        }

        const difference = targetTime - now

        if (difference <= 0)
          return { hours: '00', minutes: '00', seconds: '00' }

        return {
          hours: String(Math.floor(difference / (1000 * 60 * 60))).padStart(
            2,
            '0',
          ),
          minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            '0',
          ),
          seconds: String(Math.floor((difference / 1000) % 60)).padStart(
            2,
            '0',
          ),
        }
      }

      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    }, [])

    return (
      <div className='flex flex-col justify-center items-center text-center py-6'>
        <div className='text-[4.5vw] mb-3'>
          월~금 50%,{' '}
          <span className='font-bold text-[#FF3E3E]'>주말은 70%</span> 할인
        </div>
        <div className='text-[8vw] font-bold'>타임특가 찬스!</div>
        <div className='relative bg-[#191D28] rounded-lg mt-[10vw]'>
          <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <div className='w-[50vw] text-[3.6vw] bg-white rounded-full border border-black py-[3vw] font-bold'>
              밤 10시부터 단 2시간!
            </div>
          </div>
          <div className='w-[80vw] flex flex-col justify-center items-center py-[5vw] gap-[2vw] text-white'>
            <div className='flex justify-center items-center gap-[2vw]'>
              <div className='flex flex-col items-center'>
                <div className='bg-[#1A1A1A] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>{timeLeft.hours}</span>
                </div>
                <span className='text-[3vw]'>시</span>
              </div>
              <span className='text-[6vw] font-bold mt-[-2vw]'>:</span>
              <div className='flex flex-col items-center'>
                <div className='bg-[#1A1A1A] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>
                    {timeLeft.minutes}
                  </span>
                </div>
                <span className='text-[3vw]'>분</span>
              </div>
              <span className='text-[6vw] font-bold mt-[-2vw]'>:</span>
              <div className='flex flex-col items-center'>
                <div className='bg-[#1A1A1A] rounded-lg px-[3vw] pt-[2vw]'>
                  <span className='text-[6vw] font-bold'>
                    {timeLeft.seconds}
                  </span>
                </div>
                <span className='text-[3vw]'>초</span>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-[4vw] text-[3vw] text-gray-800'>
          * 타임특가는 전권대여 시 적용됩니다
        </div>
      </div>
    )
  }

  const TimeSaleList = () => {
    const sortedToons = toonsInfo?.sort((a, b) => {
      return (
        new Date(a.promotionInfo?.timeSale?.eventStartedAt) -
        new Date(b.promotionInfo?.timeSale?.eventStartedAt)
      )
    })

    const [currentIndex, setCurrentIndex] = useState(() => {
      const now = moment().format('YYYY-MM-DD')
      const index = sortedToons?.findIndex((toon) => {
        const startDay = moment(
          toon?.promotionInfo?.timeSale?.eventStartedAt,
        ).format('YYYY-MM-DD')
        return now === startDay
      })

      return index > 0 ? index : 0
    })
    const [isAlarmSet, setIsAlarmSet] = useState(
      !!window.localStorage.getItem(`timesale_alarm_${eventInfo?._id}`),
    )

    const containerRef = useRef(null)

    const handleScroll = (e) => {
      // 스크롤 중에는 새로운 인덱스 계산을 하지 않음
      if (isScrolling.current) return

      const container = e.target
      const scrollPosition = container.scrollLeft
      const itemWidth = window.innerWidth * 0.6 + 16
      const newIndex = Math.round(scrollPosition / itemWidth)

      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex)

        // 스크롤 스냅 동작 추가
        isScrolling.current = true
        const targetScroll = newIndex * itemWidth

        container.scrollTo({
          left: targetScroll,
          behavior: 'smooth',
        })

        // 스크롤 애니메이션이 끝나면 플래그 해제
        setTimeout(() => {
          isScrolling.current = false
        }, 100) // 스크롤 애니메이션 시간과 맞춤
      }
    }

    // 스크롤 중인지 추적하는 ref 추가
    const isScrolling = useRef(false)

    // 초기 자동 스크롤 추가
    useEffect(() => {
      if (containerRef.current && currentIndex > 0) {
        const itemWidth = window.innerWidth * 0.6 + 16
        const targetScroll = currentIndex * itemWidth

        // DOM이 완전히 렌더링된 후 스크롤 실행
        setTimeout(() => {
          if (containerRef?.current) {
            containerRef.current.scrollTo({
              left: targetScroll,
              behavior: 'instant', // 초기 스크롤은 즉시 이동
            })
          }
        }, 100)
      }
    }, [currentIndex])

    const getOverlayContent = (toon) => {
      const now = new Date()
      const startDate = new Date(toon?.promotionInfo?.timeSale?.eventStartedAt)
      const endDate = new Date(toon?.promotionInfo?.timeSale?.eventEndedAt)

      if (now > startDate && now < endDate) {
        return null
      }

      if (now > endDate) {
        return (
          <div className='absolute inset-0 bg-black/70 flex items-center justify-center'>
            <span className='text-center text-white text-[5vw] font-bold'>
              종료된 <br /> 타임특가에요
            </span>
          </div>
        )
      }

      if (now < startDate) {
        return (
          <div className='absolute inset-0 bg-black/70 flex items-center justify-center'>
            <span className='text-center text-white text-[5vw] font-bold'>
              {formatDateToKorean(startDate)} <br />
              22시 오픈 예정
            </span>
          </div>
        )
      }

      return null
    }

    const getButtonContent = (toon, index) => {
      const now = new Date()
      const startDate = new Date(toon?.promotionInfo?.timeSale?.eventStartedAt)
      const endDate = new Date(toon?.promotionInfo?.timeSale?.eventEndedAt)

      if (now > endDate) {
        return (
          <button
            type='button'
            className='w-full mt-2 py-2 bg-gray-200 text-gray-600 rounded-lg text-[3.4vw] font-bold'
            disabled
          >
            타임특가 마감
          </button>
        )
      }

      if (now < startDate) {
        // Todo 특가 시작 전 알림받기 버튼 추가
        return (
          <button
            type='button'
            className='w-full mt-2 py-2 bg-gray-200 text-gray-600 rounded-lg text-[3.4vw] font-bold'
          >
            {/* 오픈 알림 받기 */}
            오픈 예정
          </button>
        )
      }

      return (
        <button
          type='button'
          className='w-full mt-2 py-2 bg-black text-white rounded-lg text-[3.4vw] font-bold'
        >
          보러가기
        </button>
      )
    }

    return (
      <div className='py-[6vw] bg-[#F6F6FA]'>
        <TimerSection />

        <div
          ref={containerRef}
          className='overflow-x-auto scrollbar-hide pb-[4vw]'
          style={{
            scrollSnapType: 'x mandatory',
            scrollPadding: '0 20%',
            WebkitOverflowScrolling: 'touch',
            scrollBehavior: 'smooth', // 부드러운 스크롤 추가
          }}
          onScroll={handleScroll}
        >
          <div className='inline-flex gap-4 px-[20%]'>
            {sortedToons?.map((toon, index) => {
              const eventDate = moment(
                toon?.promotionInfo?.timeSale?.eventStartedAt,
              )
              const dateText = `${eventDate.format('DD')}. ${getDayOfWeek(
                eventDate,
              )}`

              return (
                <div
                  key={toon?._id}
                  className='w-[60vw] flex-shrink-0 cursor-pointer transition-all duration-300 ease-in-out'
                  style={{
                    scrollSnapAlign: 'center',
                    scrollSnapStop: 'always', // 한 번에 한 항목씩만 스크롤
                    transform: `scale(${currentIndex === index ? 1 : 0.9})`,
                    opacity: currentIndex === index ? 1 : 0.7,
                  }}
                  onClick={() => {
                    // Todo : 성인 인증 확인이 안 됨. MainToonComponent로 이관을 고려
                    push('AltoonDetailPage', {
                      toonId: toon?._id,
                      component: 'AltoonEventPage_timeSale',
                    })
                  }}
                >
                  <div className='bg-white rounded-xl overflow-hidden shadow-md border border-gray-100'>
                    <div className='relative'>
                      <img
                        src={toon?.thumbnail}
                        alt={toon?.title}
                        className='w-full aspect-[3/4] object-cover'
                      />
                      <div className='absolute top-2 right-2 bg-[#FF3E3E] text-white px-2 py-1 rounded-md text-[3.2vw] font-bold'>
                        {toon?.promotionInfo?.timeSale?.discountRate || '??'}%
                      </div>
                      {getOverlayContent(toon)}
                    </div>
                    <div className='p-3'>
                      {toon?.promotionInfo?.timeSale?.eventStartedAt && (
                        <div className='w-[14vw] px-[2vw] py-[1vw] mb-[2vw] bg-black rounded-full text-center text-[3.2vw] text-white font-bold'>
                          {dateText}
                        </div>
                      )}
                      <div className='font-bold text-[3.8vw] mb-1 truncate'>
                        {toon?.title}
                      </div>
                      <div className='flex items-end gap-1'>
                        <span className='text-[3.6vw] text-gray-400 line-through'>
                          {toon.toonPrice}코인
                        </span>
                        <span className='text-[5vw] text-red-500 font-bold'>
                          {toon?.promotionInfo?.timeSale?.salePrice || '???'}
                          코인
                        </span>
                      </div>
                      {getButtonContent(toon, index)}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/* //Todo 특가 시작 전 알림받기 버튼 추가 */}
        <button
          type='button'
          className={
            isAlarmSet
              ? 'flex items-center justify-center gap-[2vw] ml-[20vw] w-[60vw] mt-4 py-3 border rounded-full text-[4.2vw] font-bold text-gray-400'
              : 'flex items-center justify-center gap-[2vw] ml-[20vw] w-[60vw] mt-4 py-3 border-black border-[0.6vw] rounded-full text-[4.2vw] font-bold bg-white'
          }
          disabled={isAlarmSet}
          onClick={async () => {
            const alarmSet = await backendApis.setToonAlarm('timesale')
            if (alarmSet?.status === 200) {
              ToastStore.toastOn({
                type: 'emoji',
                emoji: '🔔',
                message: '타임특가 10분 전에 알려드릴게요!',
                duration: 2000,
              })
              setIsAlarmSet(true)
              window.localStorage.setItem(
                `timesale_alarm_${eventInfo?._id}`,
                'true',
              )
            } else {
              ToastStore.toastOn({
                type: 'error',
                message:
                  '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                duration: 2000,
              })
            }
          }}
        >
          {isAlarmSet ? (
            <div>알림받는 중</div>
          ) : (
            <>
              <img
                src='/images/toon/eventPage/alarmBell.png'
                alt='alarm'
                className='w-[6vw] h-[6vw]'
              />
              <div>특가 시작 전 알림받기</div>
            </>
          )}
        </button>
      </div>
    )
  }

  const ThemeSaleList = () => {
    return (
      <div className='bg-white px-[4vw] py-[6vw]'>
        <h1 className='text-center text-[4vw] mt-[2vw]'>
          2024년, 사랑받은 베스트 작품
        </h1>
        <h2 className='text-center text-[8vw] font-bold mb-8'>
          최대 30%할인 기획전
        </h2>

        <div className='grid grid-cols-3 gap-[2vw]'>
          {toonsInfo?.map((toon, index) => (
            <MainToonComponent
              key={toon._id}
              toon={toon}
              index={index}
              type='themeSale'
              component='AltoonEventPage_themeSale'
            />
          ))}
        </div>
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div className='w-full px-6 pt-4 pb-[10vw] bg-gray-200'>
        <div className='text-[3.6vw] font-semibold'>유의사항</div>
        <THImageText
          fontSize={2.8}
          style={{
            color: '#8E8D9B',
          }}
          text={`${eventInfo?.notice}`}
        />
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        style={{
          backgroundColor: `#${eventInfo?.backgroundColor}`,
        }}
      >
        {eventInfo && (
          <>
            {isFrom !== 'AltoonMainEventPage' && <HeaderComponent />}

            {(!eventInfo?.eventType || eventInfo.eventType === 'basic') && (
              <>
                <MainImageSection />
                {eventInfo?.ctaOneInfo?.ctaText && <CTAButtonOne />}
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.ctaTwoInfo?.ctaText && <CTAButtonTwo />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'poll' && (
              <>
                <MainImageSection />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <PollList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'leaderboard' && (
              <>
                <MainImageSection />
                <LeaderboardSection />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'timeSale' && (
              <>
                <MainImageSection />
                {eventInfo?.toonIdList?.length > 0 && <TimeSaleList />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo.eventType === 'themeSale' && (
              <>
                <MainImageSection />
                {eventInfo?.toonIdList?.length > 0 && <ThemeSaleList />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}

            {eventInfo?.eventType === 'drama' && (
              <>
                <MainImageSection />
                <DramaPosterSection
                  dramaId={eventInfo?.dramaId}
                  posterImage={eventInfo?.dramaPosterImage}
                />
                {(eventInfo?.toonIdList?.length > 0 ||
                  eventInfo?.novelIdList?.length > 0) && <ToonsAndNovelsList />}
                {eventInfo?.subImageUri && <SubImageSection />}
                {eventInfo?.notice && <NoticeSection />}
              </>
            )}
          </>
        )}
        {isLoading && <LoadingIndicator isLoading={isLoading} />}
      </div>
    </AppScreen>
  )
})

export default AltoonEventPage
