import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import BottomBarComponent from '../components/toon/BottomBarComponent'
import MainNovelComponent from '../components/toon/MainNovelComponent'
import MypageNovelComponent from '../components/toon/mypageNovelComponent'
import MypageToonComponent from '../components/toon/mypageToonComponent'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import { CloseRounded } from '../images/dailyCheckIn'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

const AltoonMyPage = observer(() => {
  const token = useSearchParam('token')
  // const navigate = useNavigate()
  const { pop } = useMyFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [recentlyViewedToons, setRecentlyViewedToons] = useState([])
  const [likedToons, setLikedToons] = useState([])
  const [likedNovels, setLikedNovels] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedWorkInfo, setSelectedWorkInfo] = useState(null)

  const handleRemoveToon = async () => {
    try {
      const result = await backendApis.removeFromViewLog({
        type: selectedWorkInfo?.type,
        id: selectedWorkInfo?.id,
      })

      if (result?.status === 200) {
        setRecentlyViewedToons((prev) =>
          prev.filter((toon) => toon._id !== selectedWorkInfo?.id),
        )
        ToastStore.toastOn({
          type: 'emoji',
          emoji: '✅',
          message: '작품이 삭제되었어요',
          duration: 2000,
        })
      } else {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
      }
    } catch (error) {
      console.error('Failed to remove toon:', error)
      ToastStore.toastOn({
        type: 'error',
        message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
        duration: 2000,
      })
    }
    setIsModalOpen(false)
  }

  const DeleteModal = () => {
    if (!isModalOpen) return null

    return (
      <>
        <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
        <div className='fixed top-[30vh] right-[10vw] z-30 w-4/5 h-[30vh] bg-white flex flex-col items-center justify-between rounded-xl'>
          <div className='py-[2vh] text-[5vw] font-bold'>안내</div>
          <div className='text-[4.6vw]'>
            <span className='font-bold'>
              [
              {selectedWorkInfo?.title?.length > 14
                ? `${selectedWorkInfo?.title?.slice(0, 14)}..`
                : selectedWorkInfo?.title}
              ]
            </span>{' '}
            작품을
          </div>
          <div className='mb-[4vw] text-[4.6vw]'>
            최근 본 목록에서 삭제하시겠어요?
          </div>
          <div className='w-full'>
            <button
              type='button'
              className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
              onClick={() => {
                setIsModalOpen(false)
              }}
            >
              취소
            </button>
            <button
              type='button'
              className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
              onClick={handleRemoveToon}
            >
              확인
            </button>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      const recentlyViewedToonsResult =
        await backendApis.loadRecentlyViewedToons()
      if (recentlyViewedToonsResult?.status === 200) {
        const dataArray = Array.isArray(recentlyViewedToonsResult?.data)
          ? recentlyViewedToonsResult.data
          : []

        const sortedData = dataArray
          .filter((toon) => !toon?.viewLog?.removedFromViewedList)
          .sort((a, b) => {
            const aTime = new Date(a?.viewLog?.lastViewedAt).getTime()
            const bTime = new Date(b?.viewLog?.lastViewedAt).getTime()
            return bTime - aTime
          })
        setRecentlyViewedToons(sortedData)
      }
    }

    const fetchLikedToons = async () => {
      const result = await backendApis.loadLikedToonsAndNovels()
      if (result?.status === 200) {
        const { toons, novels } = result.data
        setLikedToons(toons || [])
        setLikedNovels(novels || [])
      }
    }
    fetchData()
    fetchLikedToons()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='flex font-bold'>보관함</div>
        <div className='w-[16vw]' />
      </section>
    )
  }

  const ToonList = () => {
    const [selectedType, setSelectedType] = useState('viewed')
    const [borderStyle, setBorderStyle] = useState({})

    const viewedRef = useRef(null)
    const likedRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedType) {
        case 'viewed':
          activeRef = viewedRef
          break
        case 'liked':
          activeRef = likedRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedType])

    const getButtonStyle = (genre) => {
      if (genre === selectedType) {
        return 'px-4 font-bold text-[#E4443B]' // 선택된 탭 스타일
      }
      return 'px-4 text-gray-500' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center mb-[12vh]'>
        <div className='w-full flex flex-row justify-between px-10 py-2 mb-4 text-[4.2vw] border-b border-[#F3F3F3] relative'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.3vh] h-[0.4vh] bg-[#E4443B] transition-all duration-300'
          />

          <button
            ref={viewedRef}
            type='button'
            className={getButtonStyle('viewed')}
            onClick={() => setSelectedType('viewed')}
          >
            최근 감상한 작품
          </button>
          <button
            ref={likedRef}
            type='button'
            className={getButtonStyle('liked')}
            onClick={() => setSelectedType('liked')}
          >
            좋아한 작품
          </button>
        </div>

        <div className='w-full px-4 flex flex-col'>
          {selectedType === 'viewed' && recentlyViewedToons?.length === 0 && (
            <MypageToonComponent type={selectedType} isEmpty />
          )}
          {selectedType === 'viewed' &&
            recentlyViewedToons?.length > 0 &&
            recentlyViewedToons?.map((toon, index) =>
              toon?.viewLog?.toonId ? (
                <div key={toon?._id} className='relative'>
                  <MypageToonComponent
                    key={toon?._id}
                    toon={toon}
                    index={index}
                    type={selectedType}
                    component='mypage_recentlyViewed'
                  />
                  <button
                    type='button'
                    aria-label='delete'
                    className='absolute top-[2vw] right-[2vw]'
                    onClick={() => {
                      setSelectedWorkInfo({
                        type: 'toon',
                        id: toon?._id,
                        title: toon?.title,
                      })
                      setIsModalOpen(true)
                    }}
                  >
                    <CloseRounded className='w-[6vw] h-[6vw]' />
                  </button>
                </div>
              ) : (
                <div key={toon?._id} className='relative'>
                  <MainNovelComponent
                    key={toon?._id}
                    novel={toon}
                    index={index}
                    type='mypage_recently_viewed'
                  />
                  <button
                    type='button'
                    aria-label='delete'
                    className='absolute top-[2vw] right-[2vw]'
                    onClick={() => {
                      setSelectedWorkInfo({
                        type: 'novel',
                        id: toon?._id,
                        title: toon?.title,
                      })
                      setIsModalOpen(true)
                    }}
                  >
                    <CloseRounded className='w-[6vw] h-[6vw]' />
                  </button>
                </div>
              ),
            )}
          {selectedType === 'liked' &&
            likedToons?.length === 0 &&
            likedNovels?.length === 0 && (
              <MypageToonComponent type={selectedType} isEmpty />
            )}
          {selectedType === 'liked' &&
            likedToons?.length > 0 &&
            likedToons?.map((toon, index) => (
              <MypageToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type={selectedType}
                component='mypage_liked'
              />
            ))}

          {selectedType === 'liked' &&
            likedNovels?.length > 0 &&
            likedNovels?.map((novel, index) => (
              <MypageNovelComponent
                key={novel?._id}
                novel={novel}
                index={index}
                type={selectedType}
              />
            ))}
        </div>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <AppScreen>
      <div className='w-full overflow-hidden'>
        <HeaderComponent />
        <ToonList />
        <BottomBarComponent />

        <DeleteModal />

        {isLoading && <LoadingIndicator />}
      </div>
    </AppScreen>
  )
})

export default AltoonMyPage
