import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import AuthStore from '../../stores/AuthStore'
import BannerAdminScreen from './BannerAdminScreen'
import ContentTabAdminScreen from './ContentTabAdminScreen'
import Header from './Header'
import MasterScreen from './MasterScreen'
import MyInfoScreen from './MyInfoScreen'
import ProviderAdminScreen from './ProviderAdminScreen'
import Sidebar from './Sidebar'
import ToonAdminScreen from './ToonAdminScreen'
import ToonCommentAdminScreen from './ToonCommentAdminScreen'
import ToonEventAdminScreen from './ToonEventAdminScreen'
import ToonPromotionAdminScreen from './ToonPromotionAdminScreen'
import ToonReviewAdminScreen from './ToonReviewAdminScreen'
import WelcomeScreen from './WelcomeScreen'

const AdminScreen = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  // useEffect(() => {
  //   Log.event('entering_screen', location.pathname)
  //   if (
  //     SellerStore?.sellerInfo?.sellerName &&
  //     [''].includes(SellerStore.sellerInfo?.sellerName)
  //   ) {
  //     AuthStore.setToken([])
  //     localStorage.removeItem('@alwayz@provider@token@')
  //     navigate('/login')
  //   }
  // }, [location])

  useEffect(() => {
    const fetchData = async () => {
      const result = await AltoonCMSUserStore.loadProviderInfo()
      if (!result) {
        alert('콘텐츠 제공자 정보가 없습니다.')
        localStorage.removeItem('@altoon@provider@token@')
        AuthStore.setToken('')
        navigate('/altoon-cms-login')
      }
    }
    fetchData()
  }, [])

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={AltoonCMSUserStore?.isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-row flex-1 h-full bg-dashboard-background selectable-page'>
        <div
          style={{
            position: 'fixed',
            width: 240,
            height: '100%',
            zIndex: 15,
            display: 'block',
          }}
        >
          <Sidebar />
        </div>
        <div className='z-10 flex flex-col grow pl-60'>
          <Header />
          <div className='flex flex-col items-start px-10 pt-16'>
            {AltoonCMSUserStore?.isMasterAccount === true && (
              <>
                {AltoonCMSUserStore?.currentScreen === 'main' && (
                  <MasterScreen />
                )}
                {AltoonCMSUserStore?.currentScreen === 'toonAdmin' && (
                  <ToonAdminScreen />
                )}
                {AltoonCMSUserStore?.currentScreen === 'toonReviewAdmin' && (
                  <ToonReviewAdminScreen />
                )}
                {AltoonCMSUserStore?.currentScreen === 'commentAdmin' && (
                  <ToonCommentAdminScreen />
                )}
                {AltoonCMSUserStore?.currentScreen === 'providerAdmin' && (
                  <ProviderAdminScreen />
                )}
                {AltoonCMSUserStore?.currentScreen === 'bannerAdmin' && (
                  <BannerAdminScreen />
                )}
                {AltoonCMSUserStore?.currentScreen ===
                  'toonEventAdminScreen' && <ToonEventAdminScreen />}
                {AltoonCMSUserStore?.currentScreen ===
                  'contentTabAdminScreen' && <ContentTabAdminScreen />}
                {AltoonCMSUserStore?.currentScreen ===
                  'toonPromotionAdminScreen' && <ToonPromotionAdminScreen />}
              </>
            )}
            {AltoonCMSUserStore?.isMasterAccount === false && (
              <>
                {AltoonCMSUserStore?.currentScreen === 'main' && (
                  <WelcomeScreen />
                )}
              </>
            )}
            {AltoonCMSUserStore?.currentScreen === 'myInfo' && <MyInfoScreen />}
          </div>
        </div>
      </div>
      {AltoonCMSUserStore?.isLoading && <LoadingIndicator />}
    </>
  )
})

export default AdminScreen
