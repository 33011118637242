import React, { useEffect, useState } from 'react'

const SearchForm = ({ onSearch, activeSearchQuery, activeSearchType }) => {
  const [searchQuery, setSearchQuery] = useState(activeSearchQuery || '')
  const [searchType, setSearchType] = useState(activeSearchType || 'title')

  useEffect(() => {
    setSearchQuery(activeSearchQuery || '')
    setSearchType(activeSearchType || 'title')
  }, [activeSearchQuery, activeSearchType])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch({ searchQuery, searchType })
  }

  const handleReset = () => {
    setSearchQuery('')
    setSearchType('title')
    onSearch({ searchQuery: '', searchType: 'title' })
  }

  return (
    <form
      onSubmit={handleSubmit}
      className='pb-4 flexRow items-center space-x-4'
    >
      <select
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        className='pr-8 py-1 border rounded'
      >
        <option value='title'>제목</option>
        <option value='id'>ID</option>
        <option value='provider'>제공자</option>
      </select>
      <input
        type='text'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder='검색어를 입력하세요'
        className='px-2 py-1 border rounded w-64'
      />
      <button
        type='submit'
        className='px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
      >
        검색
      </button>
      {activeSearchQuery && (
        <button
          type='button'
          onClick={handleReset}
          className='px-4 py-1 bg-gray-500 text-white rounded hover:bg-gray-600'
        >
          초기화
        </button>
      )}
    </form>
  )
}

export default SearchForm
