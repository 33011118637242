import { action, makeAutoObservable, makeObservable, observable } from 'mobx'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import AuthStore from '../AuthStore'

class AltoonCMSStore {
  provider = ''
  providerId = ''
  providerInfo = {}
  currentScreen = 'main'
  isMasterAccount = false
  dashboardInfoByToonId = []
  dashboardInfoByDate = []
  dashboardTotalInfo = []
  userflowData = []
  dateType = 'month'
  selectedMonth = new Date()
  startDate = new Date()
  endDate = new Date()
  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setProvider(provider) {
    this.provider = provider
  }

  setProviderId(providerId) {
    this.providerId = providerId
  }

  setProviderInfo(providerInfo) {
    this.providerInfo = providerInfo
  }

  setDashboardInfoByToonId(dataByToonId) {
    this.dashboardInfoByToonId = dataByToonId
  }

  setCurrentScreen(currentScreen) {
    this.currentScreen = currentScreen
  }

  setDashboardInfoByDate(dataByDate) {
    this.dashboardInfoByDate = dataByDate
  }

  setToonViewDataInfoByDate(viewDataInfoByDate) {
    this.toonViewDataInfoByDate = viewDataInfoByDate
  }

  setToonPaidDataInfoByDate(paidDataInfoByDate) {
    this.toonPaidDataInfoByDate = paidDataInfoByDate
  }

  setSelectedMonth(selectedMonth) {
    this.selectedMonth = selectedMonth
    // console.log('selectedMonth: ', selectedMonth.getMonth())
  }

  setDateType(dateType) {
    this.dateType = dateType
  }

  setStartDate(startDate) {
    this.startDate = startDate
  }

  setEndDate(endDate) {
    this.endDate = endDate
  }

  setDashboardTotalInfo(dashboardTotalInfo) {
    this.dashboardTotalInfo = dashboardTotalInfo
  }

  setUserflowData(userflowData) {
    this.userflowData = userflowData
  }

  setIsMasterAccount(isMasterAccount) {
    this.isMasterAccount = isMasterAccount
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading
  }

  async loadProviderInfo() {
    const tempProviderId = localStorage.getItem('@altoon@provider@')
    const tempToken = localStorage.getItem('@altoon@provider@token@')
    // fetch provider info
    const result = await altoonCMSBackendApis.loadProviderInfo(
      tempToken ?? AuthStore?.token,
      {
        providerId: tempProviderId ?? this.providerId,
      },
    )

    // console.log('loadProviderInfo result: ', result)

    if (result?.status === 200 && result?.data) {
      this.setProviderInfo(result.data)
      this.setIsMasterAccount(result.data?.isMasterAccount || false)
      return true
    }
    return false
  }

  async loadDashboardInfo(selectedProviderId, selectedToonId) {
    if (this.dateType === 'period' && this.startDate && this.endDate) {
      return this.loadDashboardInfoInPeriod(selectedProviderId, selectedToonId)
    }

    const tempProviderId = localStorage.getItem('@altoon@provider@')
    const tempToken = localStorage.getItem('@altoon@provider@token@')
    // fetch dashboard info
    const providerIdForAPI =
      selectedProviderId?.length > 0
        ? selectedProviderId
        : tempProviderId ?? this.providerId

    const result = await altoonCMSBackendApis.loadDashboardInfo(
      tempToken ?? AuthStore?.token,
      {
        providerId: providerIdForAPI,
        month: this.selectedMonth.getMonth() + 1,
        year: this.selectedMonth.getFullYear(),
        isAdmin: this.isMasterAccount,
      },
    )

    if (result?.status === 200 && result?.data) {
      let dataArray = Array.from(result?.data)
      if (selectedProviderId) {
        dataArray = Array.from(
          result?.data.filter((item) => item.providerId === selectedProviderId),
        )
      }
      if (selectedToonId) {
        dataArray = Array.from(
          result?.data.filter((item) => item.toonId === selectedToonId),
        )
      }
      this.setDashboardTotalInfo(dataArray)

      /**
       * Creates an object where the keys are the toonIds and the values are arrays of items with the same toonId.
       *
       * @param {Array} dataArray - The array of items to be grouped by toonId.
       * @returns {Object} - An object where the keys are the toonIds and the values are arrays of items with the same toonId.
       */
      const dataByToonId = dataArray.reduce((acc, item) => {
        if (!acc[item.toonId]) {
          acc[item.toonId] = []
        }
        acc[item.toonId].push(item)
        return acc
      }, {})

      // Convert each group to formattedData format by toon id
      const formattedDataByToonId = Object.values(dataByToonId).map(
        (items, index) => {
          const firstItem = items[0]
          return {
            index: index + 1,
            toonId: firstItem.toonId,
            toonTitle: firstItem.toonTitle,
            providerDisplayName: firstItem.providerDisplayName,
            totalQuantity:
              /// If the settlementFeeRate is 1, then 매출 맻 정산내역 보여주지 않음.
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce((sum, item) => sum + item.totalQuantity || 0, 0)
                : '-',
            settlementFeeRate: (1 - firstItem.settlementFeeRate) * 100 || 0,
            settlementAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementAmount || 0),
                    0,
                  )
                : '-',
            settlementAppStoreFeeAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) =>
                      sum + (item.settlementAppStoreFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementAlwayzFeeAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementAlwayzFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementAmountWithoutAppStoreFee:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) =>
                      sum +
                      (item.settlementAmount -
                        item.settlementAppStoreFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementConfirmAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementConfirmAmount || 0),
                    0,
                  )
                : '-',
          }
        },
      )
      this.setDashboardInfoByToonId(formattedDataByToonId)

      const dataByDate = dataArray.reduce((acc, item) => {
        const dateObject = new Date(item.createdDate)
        const date = dateObject.toISOString().split('T')[0] // Get the date part (yyyy-mm-dd)
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(item)
        return acc
      }, {})

      const totalSalesByDate = Object.entries(dataByDate).reduce(
        (acc, [date, items]) => {
          const totalSalesAmount = items.reduce(
            (sum, item) =>
              sum +
              (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? item.settlementAmount || 0
                : 0),
            0,
          )
          acc[date] = totalSalesAmount
          return acc
        },
        {},
      )
      // console.log('totalSettlementAmountByDate: ', totalSalesByDate)

      this.setDashboardInfoByDate(totalSalesByDate)

      if (this.isMasterAccount === true) {
        // 조회 고객(unique) 데이터
        const toonViewDataByDate = Object.entries(dataByDate).reduce(
          (acc, [date, items]) => {
            const totalViewCount = items.reduce(
              (sum, item) =>
                sum +
                (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                  ? item?.viewInfo?.viewCount || 0
                  : 0),
              0,
            )
            acc[date] = totalViewCount
            return acc
          },
          {},
        )
        this.setToonViewDataInfoByDate(toonViewDataByDate)

        // 구매 고객(unique) 데이터
        const toonPaidDataByDate = Object.entries(dataByDate).reduce(
          (acc, [date, items]) => {
            const totalPaidUserCount = items.reduce(
              (sum, item) =>
                sum +
                (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                  ? item?.paidInfo?.payingUserCount || 0
                  : 0),
              0,
            )
            acc[date] = totalPaidUserCount
            return acc
          },
          {},
        )
        this.setToonPaidDataInfoByDate(toonPaidDataByDate)
      }

      return true
    }
    return false
  }

  async loadDashboardInfoInPeriod(selectedProviderId, selectedToonId) {
    const tempProviderId = localStorage.getItem('@altoon@provider@')
    const tempToken = localStorage.getItem('@altoon@provider@token@')
    // fetch dashboard info
    const providerIdForAPI =
      selectedProviderId?.length > 0
        ? selectedProviderId
        : tempProviderId ?? this.providerId

    const result = await altoonCMSBackendApis.loadDashboardInfoInPeriod(
      tempToken ?? AuthStore?.token,
      {
        providerId: providerIdForAPI,
        startDate: this.startDate.toISOString().split('T')[0],
        endDate: this.endDate.toISOString().split('T')[0],
        isAdmin: this.isMasterAccount,
      },
    )

    if (result?.status === 200 && result?.data) {
      let dataArray = Array.from(result?.data)
      if (selectedProviderId) {
        dataArray = Array.from(
          result?.data.filter((item) => item.providerId === selectedProviderId),
        )
      }
      if (selectedToonId) {
        dataArray = Array.from(
          result?.data.filter((item) => item.toonId === selectedToonId),
        )
      }

      this.setDashboardTotalInfo(dataArray)

      /**
       * Creates an object where the keys are the toonIds and the values are arrays of items with the same toonId.
       *
       * @param {Array} dataArray - The array of items to be grouped by toonId.
       * @returns {Object} - An object where the keys are the toonIds and the values are arrays of items with the same toonId.
       */
      const dataByToonId = dataArray.reduce((acc, item) => {
        if (!acc[item.toonId]) {
          acc[item.toonId] = []
        }
        acc[item.toonId].push(item)
        return acc
      }, {})

      // Convert each group to formattedData format by toon id
      const formattedDataByToonId = Object.values(dataByToonId).map(
        (items, index) => {
          const firstItem = items[0]
          return {
            index: index + 1,
            toonId: firstItem.toonId,
            toonTitle: firstItem.toonTitle,
            providerDisplayName: firstItem.providerDisplayName,
            totalQuantity:
              /// If the settlementFeeRate is 1, then 매출 맻 정산내역 보여주지 않음.
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce((sum, item) => sum + item.totalQuantity || 0, 0)
                : '-',
            settlementFeeRate: (1 - firstItem.settlementFeeRate) * 100 || 0,
            settlementAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementAmount || 0),
                    0,
                  )
                : '-',
            settlementAppStoreFeeAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) =>
                      sum + (item.settlementAppStoreFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementAlwayzFeeAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementAlwayzFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementAmountWithoutAppStoreFee:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) =>
                      sum +
                      (item.settlementAmount -
                        item.settlementAppStoreFeeAmount || 0),
                    0,
                  )
                : '-',
            settlementConfirmAmount:
              firstItem.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? items.reduce(
                    (sum, item) => sum + (item.settlementConfirmAmount || 0),
                    0,
                  )
                : '-',
          }
        },
      )
      this.setDashboardInfoByToonId(formattedDataByToonId)

      const dataByDate = dataArray.reduce((acc, item) => {
        const dateObject = new Date(item.createdDate)
        const date = dateObject.toISOString().split('T')[0] // Get the date part (yyyy-mm-dd)
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(item)
        return acc
      }, {})

      const totalSalesByDate = Object.entries(dataByDate).reduce(
        (acc, [date, items]) => {
          const totalSalesAmount = items.reduce(
            (sum, item) =>
              sum +
              (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                ? item.settlementAmount || 0
                : 0),
            0,
          )
          acc[date] = totalSalesAmount
          return acc
        },
        {},
      )
      // console.log('totalSettlementAmountByDate: ', totalSalesByDate)

      this.setDashboardInfoByDate(totalSalesByDate)

      if (this.isMasterAccount === true) {
        // 조회 고객(unique) 데이터
        const toonViewDataByDate = Object.entries(dataByDate).reduce(
          (acc, [date, items]) => {
            const totalViewCount = items.reduce(
              (sum, item) =>
                sum +
                (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                  ? item?.viewInfo?.viewCount || 0
                  : 0),
              0,
            )
            acc[date] = totalViewCount
            return acc
          },
          {},
        )
        this.setToonViewDataInfoByDate(toonViewDataByDate)

        // 구매 고객(unique) 데이터
        const toonPaidDataByDate = Object.entries(dataByDate).reduce(
          (acc, [date, items]) => {
            const totalPaidUserCount = items.reduce(
              (sum, item) =>
                sum +
                (item.settlementFeeRate !== 1 || this.isMasterAccount === true
                  ? item?.paidInfo?.payingUserCount || 0
                  : 0),
              0,
            )
            acc[date] = totalPaidUserCount
            return acc
          },
          {},
        )
        this.setToonPaidDataInfoByDate(toonPaidDataByDate)
      }

      return true
    }
    return false
  }

  async loadMainpageUserflow(startDate, endDate) {
    const tempToken = localStorage.getItem('@altoon@provider@token@')

    this.setIsLoading(true)
    const result = await altoonCMSBackendApis.loadMainpageUserflow(
      tempToken ?? AuthStore?.token,
      {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      },
    )

    if (result?.status === 200) {
      this.setUserflowData(result?.data)
    }
    this.setIsLoading(false)
  }
}

const container = {}
container.instance = new AltoonCMSStore()
export default container.instance
