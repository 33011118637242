import { useState } from 'react'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const UpdateContentsSection = ({ fetchData }) => {
  const [contentId, setContentId] = useState('')
  const [contentType, setContentType] = useState('')
  const [field, setField] = useState('')
  const [value, setValue] = useState('')

  const contentTypeOptions = [
    { value: 'altoon', label: '작품' },
    { value: 'banner_altoon_eventId', label: '배너_이벤트' },
    { value: 'banner_altoon_main', label: '배너_메인' },
    { value: 'tabInfo', label: '탭 정보' },
    { value: 'noticeInfo', label: '공지사항' },
  ]

  const getFieldOptions = (type) => {
    const commonFields = [{ value: 'isDisplayed', label: '노출 여부' }]

    switch (type) {
      case 'altoon':
        return [
          ...commonFields,
          { value: 'title', label: '제목' },
          { value: 'description', label: '설명' },
          { value: 'src', label: '이미지 URL' },
          { value: 'uniqueId', label: '고유 ID' },
          { value: 'displayEndedAt', label: '노출 종료일' },
          { value: 'priority', label: '우선순위' },
        ]
      case 'banner_altoon_eventId':
      case 'banner_altoon_main':
        return [
          ...commonFields,
          { value: 'title', label: '제목' },
          { value: 'description', label: '설명' },
          { value: 'src', label: '이미지 URL' },
          { value: 'displayEndedAt', label: '노출 종료일' },
        ]
      case 'tabInfo':
        return [
          ...commonFields,
          { value: 'title', label: '제목' },
          { value: 'toonIdList', label: '작품 ID 목록' },
        ]
      case 'noticeInfo':
        return [
          ...commonFields,
          { value: 'title', label: '제목' },
          { value: 'description', label: '설명' },
        ]
      default:
        return commonFields
    }
  }

  const renderValueInput = () => {
    if (!field) return null

    switch (field) {
      case 'isDisplayed':
        return (
          <div className='flex gap-4'>
            <label className='flex items-center'>
              <input
                type='radio'
                checked={value === 'true'}
                onChange={() => setValue('true')}
                className='mr-2'
              />
              노출
            </label>
            <label className='flex items-center'>
              <input
                type='radio'
                checked={value === 'false'}
                onChange={() => setValue('false')}
                className='mr-2'
              />
              비노출
            </label>
          </div>
        )
      case 'displayEndedAt':
        return (
          <input
            type='date'
            className='w-full p-2 border rounded'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )
      case 'priority':
        return (
          <input
            type='number'
            className='w-full p-2 border rounded'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            min='0'
          />
        )
      case 'toonIdList':
        return (
          <textarea
            className='w-full p-2 border rounded'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={3}
            placeholder='["id1", "id2"]'
          />
        )
      case 'description':
        return (
          <textarea
            className='w-full p-2 border rounded'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={3}
          />
        )
      default:
        return (
          <input
            type='text'
            className='w-full p-2 border rounded'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )
    }
  }

  const handleSubmit = async () => {
    if (!contentId || !contentType || !field || !value) {
      alert('모든 필드를 입력해주세요')
      return
    }

    let processedValue = value
    if (field === 'toonIdList') {
      try {
        processedValue = JSON.parse(value)
      } catch (e) {
        alert('작품 ID 목록 형식이 올바르지 않습니다')
        return
      }
    } else if (field === 'priority') {
      processedValue = Number(value)
    } else if (field === 'displayEndedAt') {
      processedValue = new Date(value).toISOString()
    } else if (field === 'isDisplayed') {
      processedValue = value === 'true' // string을 boolean으로 변환
    }

    const result = await altoonCMSBackendApis.updateContentsTabInfo({
      contentsId: contentId,
      contentType,
      field: field === 'isDisplayed' ? field : 'contentInfo', // isDisplayed는 contentInfo 밖에 있음
      value:
        field === 'isDisplayed' ? processedValue : { [field]: processedValue }, // 구조에 맞게 데이터 변환
    })

    if (result?.status === 200) {
      alert('수정되었습니다')
      setContentId('')
      setContentType('')
      setField('')
      setValue('')
      fetchData()
    }
  }

  return (
    <div className='p-4 bg-white rounded-xl'>
      <div className='py-4 font-bold text-[2.6vh]'>콘텐츠 탭 수정</div>
      <div className='space-y-4'>
        <div>
          <label className='block mb-2'>콘텐츠 ID</label>
          <input
            className='w-full p-2 border rounded'
            value={contentId}
            onChange={(e) => setContentId(e.target.value)}
          />
        </div>

        <div>
          <label className='block mb-2'>콘텐츠 타입</label>
          <select
            className='w-full p-2 border rounded'
            value={contentType}
            onChange={(e) => {
              setContentType(e.target.value)
              setField('') // 콘텐츠 타입이 변경되면 필드 초기화
              setValue('') // 값도 초기화
            }}
          >
            <option value=''>선택해주세요</option>
            {contentTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {contentType && (
          <div>
            <label className='block mb-2'>수정할 필드</label>
            <select
              className='w-full p-2 border rounded'
              value={field}
              onChange={(e) => {
                setField(e.target.value)
                setValue('') // 필드가 변경되면 값 초기화
              }}
            >
              <option value=''>선택해주세요</option>
              {getFieldOptions(contentType).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {field && (
          <div>
            <label className='block mb-2'>
              {field === 'isDisplayed' ? '노출 여부 선택' : '새로운 값'}
            </label>
            {renderValueInput()}
          </div>
        )}
      </div>

      <button
        type='button'
        className='mt-4 px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300'
        disabled={!contentId || !contentType || !field || !value}
        onClick={handleSubmit}
      >
        수정하기
      </button>
    </div>
  )
}

export default UpdateContentsSection
