import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonReviewAdminScreen = observer(() => {
  const [chapterData, setChapterData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [toonId, setToonId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showReviewedOnly, setShowReviewedOnly] = useState(false)
  const [imageCount, setImageCount] = useState(3) // 앞뒤로 보여줄 이미지 개수

  // 필터링 효과 추가
  useEffect(() => {
    if (showReviewedOnly) {
      setFilteredData(chapterData.filter((item) => !item.isReviewed))
    } else {
      setFilteredData(chapterData)
    }
  }, [showReviewedOnly, chapterData])

  const renderPreviewImages = (contents) => {
    const firstImages = contents.slice(0, imageCount)
    const lastImages = contents.slice(-imageCount)

    return (
      <div className='flex flex-wrap items-center gap-2'>
        {firstImages.map((image, index) => (
          <img
            key={`first-${index}`}
            src={image}
            alt={`preview-${index}`}
            className='w-20 h-auto object-cover'
          />
        ))}
        {contents.length > imageCount * 2 && (
          <div className='mx-2 font-bold'>...</div>
        )}
        {lastImages.map((image, index) => (
          <img
            key={`last-${index}`}
            src={image}
            alt={`preview-${contents.length - imageCount + index}`}
            className='w-20 h-auto object-cover'
          />
        ))}
      </div>
    )
  }

  const handleReviewComplete = async (itemId, chapterTitle) => {
    if (!window.confirm(`[${chapterTitle}] 회차의 검수를 완료하시겠습니까?`)) {
      return
    }

    setIsLoading(true)
    try {
      const result = await altoonCMSBackendApis.updateChapterInfo({
        chapterId: itemId,
        field: 'isReviewed',
        value: true,
        isDate: false,
      })

      if (result?.status === 200) {
        const updatedData = chapterData.map((item) =>
          item._id === itemId ? { ...item, isReviewed: true } : item,
        )
        setChapterData(updatedData)
        alert('검수가 완료되었습니다.') // 성공 알림 추가
      }
    } catch (error) {
      console.error('검수 완료 처리 실패:', error)
      alert('검수 처리 중 오류가 발생했습니다.') // 에러 알림 추가
    }
    setIsLoading(false)
  }

  const ToonSearchSection = () => {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex items-center'>
          작품아이디
          <div className='mx-3'>
            <input
              type='text'
              value={toonId}
              onChange={(e) => setToonId(e.target.value)}
            />
          </div>
          <button
            type='button'
            className='px-4 py-2 bg-gray-500 text-white rounded-xl'
            onClick={async () => {
              setIsLoading(true)
              const result = await altoonCMSBackendApis.getChapterDataByToonId({
                toonId,
              })
              if (result?.status === 200) {
                const sortedData = result?.data?.sort(
                  (a, b) => a.episode - b.episode,
                )
                setChapterData(sortedData)
                setFilteredData(sortedData)
              }
              setIsLoading(false)
            }}
          >
            회차 조회하기
          </button>
        </div>
        <div className='flex items-center gap-4'>
          <label className='flex items-center gap-2'>
            <input
              type='checkbox'
              checked={showReviewedOnly}
              onChange={(e) => setShowReviewedOnly(e.target.checked)}
            />
            검토 안 된 항목만 보기
          </label>
          <div className='flex items-center gap-2'>
            <span>미리보기 이미지 개수:</span>
            <input
              type='number'
              min='1'
              max='10'
              value={imageCount}
              onChange={(e) => setImageCount(Number(e.target.value))}
              className='w-16 px-2 py-1 border rounded'
            />
          </div>
        </div>
      </div>
    )
  }

  const RegisteredChapterList = () => {
    return (
      <div className='w-full h-full bg-white'>
        <div className='py-10 text-[2.4vh] text-black text-start font-bold'>
          {chapterData?.[0]?.toonTitle}
        </div>
        {filteredData?.length > 0 && (
          <table className='w-full table-auto border-collapse border border-slate-200'>
            <thead className='whitespace-nowrap text-[1.4vh] bg-gray-100'>
              <tr>
                <th className='table-cell'>검토여부</th>
                <th className='table-cell'>아이디</th>
                <th className='table-cell'>회차번호</th>
                <th className='table-cell'>제목</th>
                <th className='table-cell'>썸네일</th>
                <th className='table-cell'>공개일</th>
                <th className='table-cell'>유/무료</th>
                <th className='table-cell'>공개여부</th>
                <th className='table-cell'>이미지 개수</th>
                <th className='table-cell'>작가의말</th>
                <th className='table-cell'>미리보기</th>
                <th className='table-cell'>검수처리</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {filteredData.map((item, index) => (
                <tr
                  key={item._id}
                  className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td>{item.isReviewed ? 'Y' : 'N'}</td>

                  <td style={{ fontSize: 4 }}>{item._id}</td>
                  <td>{item.episode}</td>
                  <td>{item.chapterTitle}</td>
                  <td>
                    <img
                      src={item.detailThumbnail}
                      alt='itemImage'
                      style={{ width: 50, height: 50 }}
                    />
                  </td>
                  <td>
                    {moment(item.publishedAt).format('YY.MM.DD')}
                    <br />
                    {moment(item.publishedAt).format('HH:mm')}
                  </td>
                  <td
                    className={`${
                      item.isFree === true ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {item.isFree === true ? '무료' : '유료'}
                  </td>
                  <td>{item.isDisplayed === true ? 'Y' : 'N'}</td>
                  <td>{item.contents.length}개</td>
                  <td>{item.authorComment}</td>
                  {item.deletedAt && (
                    <td>{moment(item.deletedAt).format('YYYY-MM-DD HH:mm')}</td>
                  )}
                  <td>{renderPreviewImages(item.contents)}</td>
                  <td>
                    {!item.isReviewed && (
                      <button
                        type='button'
                        onClick={() =>
                          handleReviewComplete(item._id, item.chapterTitle)
                        }
                        className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm'
                      >
                        검수하기
                      </button>
                    )}
                    {item.isReviewed && (
                      <span className='text-green-500'>검수완료</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!filteredData?.length > 0 && (
          <div className='flexRow justify-center items-center'>
            데이터가 없습니다.
          </div>
        )}
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='h-4' />
      <div className='p-4 bg-white py-4 flexCol items-start justify-center rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>회차 목록</div>
        <ToonSearchSection />
        <div className='w-full h-4' />
        <RegisteredChapterList />
      </div>

      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default ToonReviewAdminScreen
