import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import RegisterContentsSection from './RegisterContentsSection'
import UpdateContentsSection from './UpdateContentsSection'

const ContentTabAdminScreen = observer(() => {
  const [contentsList, setContentsList] = useState()
  const [selectedType, setSelectedType] = useState('all')

  // 콘텐츠 타입 한글 매핑
  const contentTypeKorMap = {
    all: '전체',
    altoon: '작품',
    banner_altoon_eventId: '배너_이벤트',
    banner_altoon_main: '배너_메인',
    tabInfo: '탭 정보',
    noticeInfo: '공지사항',
  }

  // 콘텐츠 타입 목록 추출
  const contentTypes = useMemo(() => {
    const types = [...new Set(contentsList?.map((item) => item.contentType))]
    return ['all', ...types]
  }, [contentsList])

  // 필터링된 콘텐츠 목록
  const filteredContents = useMemo(() => {
    if (selectedType === 'all') return contentsList
    return contentsList?.filter((item) => item.contentType === selectedType)
  }, [contentsList, selectedType])

  const fetchData = async () => {
    AltoonCMSUserStore.setIsLoading(true)
    const result = await altoonCMSBackendApis.loadContentsTabInfo()
    if (result?.status === 200) {
      const shortDramaFilteredList = result?.data?.filter(
        (item) => !item.contentType.includes('shortdrama'),
      )
      setContentsList(shortDramaFilteredList)
    }
    AltoonCMSUserStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // 필터 탭 컴포넌트
  const FilterTabs = () => {
    return (
      <div className='mb-4 flex flex-wrap gap-2'>
        {contentTypes.map((type) => (
          <button
            type='button'
            key={type}
            className={`px-4 py-2 rounded-lg ${
              selectedType === type
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() => setSelectedType(type)}
          >
            {contentTypeKorMap[type] || type}
          </button>
        ))}
      </div>
    )
  }

  const RegisteredContentsList = ({ contentsList }) => {
    return (
      <div
        className={`w-full ${
          contentsList?.length > 0 ? 'h-[40vh]' : ''
        } overflow-y-scroll bg-white`}
      >
        {contentsList?.length > 0 && (
          <table className='w-full'>
            <thead className='whitespace-nowrap text-[1vh] border-b-2'>
              <tr>
                <th>고유아이디</th>
                <th>콘텐츠 타입</th>
                <th>노출 여부</th>
                <th>제목</th>
                <th>설명</th>
                <th>이미지</th>
                <th>고유 ID</th>
                <th>노출 종료일</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {contentsList?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <td className='p-2 text-[0.8vh] w-[10vw] break-all'>
                    {item._id}
                  </td>
                  <td className='p-2'>
                    {contentTypeKorMap[item.contentType] || item.contentType}
                  </td>
                  <td className='p-2'>
                    {item.isDisplayed ? '노출' : '비노출'}
                  </td>
                  <td className='p-2'>{item.contentInfo?.title || '-'}</td>
                  <td className='p-2 w-[15vw] break-all'>
                    {item.contentInfo?.description || '-'}
                  </td>
                  <td className='p-2 text-[0.8vh]'>
                    {item.contentInfo?.src && (
                      <>
                        <img
                          src={item.contentInfo.src}
                          alt=''
                          className='w-[5vw] mx-auto'
                        />
                        {item.contentInfo.src}
                      </>
                    )}
                  </td>
                  <td className='p-2'>{item.contentInfo?.uniqueId || '-'}</td>
                  <td className='p-2'>
                    {item.contentInfo?.displayEndedAt
                      ? new Date(
                          item.contentInfo.displayEndedAt,
                        ).toLocaleDateString()
                      : '-'}
                  </td>
                  <td className='p-2'>
                    <button
                      type='button'
                      className='px-2 py-1 bg-red-500 text-white rounded'
                      onClick={async () => {
                        if (window.confirm('삭제하시겠습니까?')) {
                          const result =
                            await altoonCMSBackendApis.deleteContentsTabInfo({
                              contentsId: item._id,
                            })
                          if (result?.status === 200) {
                            alert('삭제되었습니다.')
                            fetchData()
                          }
                        }
                      }}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>콘텐츠 탭 관리</div>
        <FilterTabs />
        {filteredContents?.length > 0 && (
          <RegisteredContentsList contentsList={filteredContents} />
        )}
      </div>

      <div className='h-4' />
      <RegisterContentsSection fetchData={fetchData} />
      <div className='h-4' />
      <UpdateContentsSection fetchData={fetchData} />
    </div>
  )
})

export default ContentTabAdminScreen
