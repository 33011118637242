import React, { useEffect, useState } from 'react'

const SearchForm = ({ onSearch, activeSearchQuery }) => {
  const [searchQuery, setSearchQuery] = useState(activeSearchQuery || '')

  useEffect(() => {
    setSearchQuery(activeSearchQuery || '')
  }, [activeSearchQuery])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch({ searchQuery })
  }

  return (
    <form onSubmit={handleSubmit} className='flexRow items-center pr-[4vw]'>
      <input
        type='search'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder='제목, 작가명, 줄거리를 검색해보세요'
        className={`px-2 my-[3vw] bg-[#F6F6FA] rounded w-full border-0 text-[3.6vw] ${
          searchQuery ? 'text-[#191D28]' : 'text-[#9C9DA4]'
        }`}
      />
      {/* <button
        type='submit'
        className='ml-[2vw] px-[2.4vw] py-[2vw] border-black border text-black rounded hover:bg-gray-100 '
      >
        찾기
      </button> */}
    </form>
  )
}

export default SearchForm
